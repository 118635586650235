import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';

const StocksTransfersTable = memo(function StocksTransfersTable({ data, isLoading, onEdit }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nº</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Conferente</TableCell>
            <TableCell>Data Conferente</TableCell>
            <TableCell>De</TableCell>
            <TableCell>Para</TableCell>
            <TableCell>Comentários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundImage: !item.receiver ? 'linear-gradient(#F002, #F002)' : 'inherit',
              }}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Edit /></IconButton></TableCell>
              <TableCell component="th" scope="row">
                {item.DocNum}
              </TableCell>
              <TableCell>{item.date?.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.receiver}</TableCell>
              <TableCell>{item.receivedDate?.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.Filler}</TableCell>
              <TableCell>{item.ToWhsCode}</TableCell>
              <TableCell>{item.Comments}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={8} align="center">
                Nenhuma transferência encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default StocksTransfersTable;
