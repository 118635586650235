import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import {
  Autocomplete, Backdrop, Button, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormHelperText, Grid, InputLabel, TextField, createTheme,
} from '@mui/material';
import Cookies from 'js-cookie';

import { fetchApontamentos, fetchTimeSheetsWithTotal } from './fetchData';
import ApontamentoTable from './apontamentoTable';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { exportRelatorioApontamentos, languages } from './exportExcel';
import dynTheme from 'assets/dynTheme';

window.globalThis.dayjs = dayjs;

const RelatorioForm = ({ isOpen, onClose, isManager, colaboradores, projetos }) => {

  const [isLoadingApontamentos, setLoadingApontamentos] = useState(false);
  const [aptApiRef, setAptApiRef] = useState();
  const [apontamentos, setApontamentos] = useState([]);;
  const [colaborador, setColaborador] = useState();
  const [projeto, setProjeto] = useState();
  const [dataInicio, setDataInicio] = useState(dayjs().subtract(7, 'day'));
  const [dataFim, setDataFim] = useState(dayjs());
  const [lang, setLang] = useState(languages[0]);

  useEffect(() => {
    if (!isManager) {
      setColaborador({
        EmployeeID: Cookies.get('logemployeeID'),
        FirstName: Cookies.get('logemployeefirstname'),
        LastName: Cookies.get('logemployeelastname'),
        label: `${Cookies.get('logemployeeID')} - ${Cookies.get('logemployeefirstname')} ${Cookies.get('logemployeelastname')}`,
      });
    }
  }, [isManager]);

  useEffect(() => {
    if (colaborador && projeto) {
      setLoadingApontamentos(true);
      const sessionID = Cookies.get('sessionID');
      const employeeID = colaborador.EmployeeID;
      const financialProject = projeto['FinancialProject'];
      const startDate = dataInicio.format('YYYY-MM-DD');
      const endDate = dataFim.format('YYYY-MM-DD');
      fetchTimeSheetsWithTotal(sessionID, startDate, endDate, employeeID, financialProject).then((data) => {
        if (data != null) {
          setApontamentos(data.timesheets);
        }
      }).finally(() => setLoadingApontamentos(false));
    }
  }, [colaborador, projeto, dataInicio, dataFim]);

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const apts = Object.values(Object.fromEntries(aptApiRef.current.getSelectedRows().entries()));
    const formJson = Object.fromEntries(formData.entries());
    formJson['colaborador'] = colaborador;
    formJson['projeto'] = projeto;
    formJson['apontamentos'] = apts.sort((a, b) => {
      if (a.Date.isSame(b.Date)) {
        const c = dayjs(a.StartTime, 'HH:mm', 'pt-br');
        const d = dayjs(b.StartTime, 'HH:mm', 'pt-br');
        return c.unix() - d.unix();
      }
      return a.Date.unix() - b.Date.unix();
    });
    console.log("Submited", formJson);
    exportRelatorioApontamentos(formJson, lang);
    // handleClose(e);
  };

  const handleSelectColaborador = (e, v) => {
    e.preventDefault();
    setColaborador(v);
  }

  const handleSelectProjeto = (e, v) => {
    e.preventDefault();
    setProjeto(v);
  }

  const handleChangeDataInicio = (v) => {
    setDataInicio(v);
    setDataFim(v.add(7, 'day'));
  }

  const handleChangeDataFim = (v) => {
    setDataFim(v);
  }

  const handleSelectLang = (e, v) => {
    e.preventDefault();
    setLang(v);
  }

  return (
    <ThemeProvider theme={dynTheme}>
      <Dialog
        fullWidth
        maxWidth="xl"
        disablePortal={false}
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
          id: 'apt-form'
        }}
      >
        <DialogTitle>Gerar Relatório de Apontamentos</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingApontamentos}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
          <Grid container spacing={2} justifyContent="center" alignItems="flex-start" mt={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                defaultValue={!isManager && colaborador != null ? colaborador : undefined}
                disabled={!isManager}
                disablePortal
                options={colaboradores}
                name="colaborador"
                renderInput={(params) => <TextField {...params} label="Colaborador" required />}
                onChange={handleSelectColaborador}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disablePortal
                options={projetos}
                renderInput={(params) => <TextField {...params} label="Projeto" required />}
                onChange={handleSelectProjeto}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                  <DatePicker
                    id="dti-picker"
                    label="Data Inicial"
                    disableFuture
                    value={dataInicio}
                    onChange={handleChangeDataInicio}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                  <DatePicker
                    id="dtf-picker"
                    label="Data Final"
                    disableFuture
                    minDate={dataInicio}
                    value={dataFim}
                    onChange={handleChangeDataFim}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                disablePortal
                options={languages}
                renderInput={(params) => <TextField {...params} label="Idioma" required />}
                onChange={handleSelectLang}
                value={lang}
              />
            </Grid>
            <Grid item xs={12}>
              <ApontamentoTable apiRef={aptApiRef} setApiRef={setAptApiRef} data={apontamentos} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="reset" onClick={handleClose} variant="outlined">Cancelar</Button>
          <Button color="primary" type="submit" variant="contained">Gerar</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

RelatorioForm.defaultProps = {
  isOpen: false,
  onClose: () => { },
  isManager: false,
  colaboradores: [],
  projetos: [],
}

export default RelatorioForm;
