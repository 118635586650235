export const treeTypes = [
  { id: 'iProductionTree', label: 'Sim' },
  { id: 'iNotATree', label: 'Não' },
]

export const boolTypes = [
  { id: 'tYES', label: 'Sim' },
  { id: 'tNO', label: 'Não' },
];

export const materialTypes = [
  { id: 'mt_GoodsForReseller', label: 'Mercadoria para Revenda' },
  { id: 'mt_FinishedGoods', label: 'Matéria Prima' },
  { id: 'mt_GoodsInProcess', label: 'Embalagem' },
  { id: 'mt_IntermediateMaterial', label: 'Semi Acabado' },
  { id: 'mt_ConsumerMaterial', label: 'Uso e Consumo' },
  { id: 'mt_FixedAsset', label: 'Ativo Imobilizado' },
  { id: 'mt_Package', label: 'Produto Acabado' },
  { id: 'mt_RawMaterial', label: 'Mercadorias em Processo' },
  { id: 'mt_Other', label: 'Outros' },
  { id: 'mt_OtherInput', label: 'Outros Insumos' },
];

export const itemsGroupCodes = [
  { id: '101', label: "101 - Matérias Primas" },
  { id: '102', label: "102 - Acessórios" },
  { id: '103', label: "103 - Insumo" },
  { id: '104', label: "104 - Ativo imobilizado" },
  { id: '105', label: "105 - Administrativo" },
  { id: '106', label: "106 - Serviços" },
  { id: '107', label: "107 - Produto Acabado" },
  { id: '108', label: "108 - Semi Acabados" },
  { id: '109', label: "109 - Material Terceiros" },
  { id: '110', label: "110 - Embalagens" },
  { id: '111', label: "111 - Uso e Consumo" },
  { id: '112', label: "112 - Mercadoria p/Revenda" },
  { id: '113', label: "113 - Frete Custo" },
  { id: '115', label: "115 - MATERIAL SECUNDARIO" },
  { id: '116', label: "116 - Frete S/Compra Matéria-Prima" },
  { id: '117', label: "117 - MOBILIÁRIO" },
  { id: '118', label: "118 - SOBRAS" },
  { id: '119', label: "119 - Licenças" },
  { id: '120', label: "120 - COMODATO" },
  { id: '121', label: "121 - SMS - EPI's" },
  { id: '122', label: "122 - SMS - Uniformes" },
];

export const itemClasses = [
  { id: 'itcMaterial', label: 'Material', },
  { id: 'itcService', label: 'Serviço', },
];

export const planningSystems = [
  { id: 'bop_MRP', label: 'MRP', },
  { id: 'bop_None', label: 'Nenhum', },
];

export const procurementMethods = [
  { id: 'bom_Make', label: 'Produzir', },
  { id: 'bom_Buy', label: 'Comprar', },
];

export const itemTypes = [
  { id: 'itItems', label: 'Item', },
  { id: 'itFixedAssets', label: 'Ativo Imobilizado', },
  { id: 'itLabor', label: 'Mão de Obra', },
  { id: 'itTravel', label: 'Viagem', },
];

export var manufacturers = [];

export async function fetchItems(sessionID, code = null, materialType = null, treeType = null, foreign = null, frozen = false) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Items');
  let data = [];
  let repeat = true;

  const select = [
    'ItemCode',
    'ItemName',
    'ItemType',
    'ItemsGroupCode',
    'Manufacturer',
    'NCMCode',
    'SalesUnit',
    'SalesPackagingUnit',
    'InventoryUOM',
    'PurchaseUnit',
    'PurchasePackagingUnit',
    'ManageSerialNumbers',
    'ManageBatchNumbers',
    'LeadTime',
    'ItemClass',
    'PlanningSystem',
    'ProcurementMethod',
    'TreeType',
    'ForeignName',
    'MaterialType',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [];

  if (code) {
    filters.push(`contains(ItemCode, '${code}')`);
  } else {
    if (materialType) {
      filters.push(`MaterialType eq '${materialType}'`);
    }
    if (treeType) {
      filters.push(`TreeType eq '${treeType}'`);
    }
    if (foreign) {
      filters.push(`contains(ForeignName, '${foreign}')`);
    }
    if (frozen) {
      filters.push("Frozen eq 'tYES'");
    } else {
      filters.push("Frozen eq 'tNO'");
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['ItemCode'];
            item['materialType'] = materialTypes.find((type) => type.id === item['MaterialType'])?.label;
            item['treeType'] = treeTypes.find((type) => type.id === item['TreeType'])?.label;
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchItem(sessionID, code) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/Items('${code}')`);

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  return data;
}

export async function createItem(sessionID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Items');

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return null;
}

export async function updateItem(sessionID, code, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/Items('${code}')`);
  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return null;
}

export async function fetchManufacturers(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Manufacturers');
  let data = [];
  let repeat = true;

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push({
              id: item['Code'],
              label: `${item['Code']} - ${item['ManufacturerName']}`,
            });
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  manufacturers = data;
  return data;
}

