import dayjs from 'dayjs';

export const statuses = [
  { id: 'bost_Open', label: 'Aberta' },
  { id: 'bost_Close', label: 'Fechada' },
];

export async function fetchInventoryTransferRequests(sessionID, docNumber = null, status = null, fromWarehouse = null, toWarehouse = null, comments = null, startDate = null, endDate = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryTransferRequests');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'DocEntry, DocNum,DocDate,DueDate,FromWarehouse,ToWarehouse,Comments,DocumentStatus');
  apiUrl.searchParams.append('$orderby', 'DocEntry desc');

  const filters = [];
  if (docNumber) {
    filters.push(`contains(DocNum,'${docNumber}')`);
  } else {
    if (status) {
      filters.push(`DocumentStatus eq '${status}'`);
    }
    if (fromWarehouse) {
      filters.push(`contains(FromWarehouse,'${String(fromWarehouse).toUpperCase()}')`);
    }
    if (toWarehouse) {
      filters.push(`contains(ToWarehouse,'${String(toWarehouse).toUpperCase()}')`);
    }
    if (comments) {
      filters.push(`contains(Comments,'${comments}')`);
    }
    if (startDate) {
      filters.push(`DueDate ge '${startDate}'`);
    }
    if (endDate) {
      filters.push(`DueDate le '${endDate}'`);
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['DocEntry'];
            item['Status'] = item['DocumentStatus'] ? statuses.find(status => status.id === item['DocumentStatus']).label : item['DocumentStatus'];
            item['created'] = dayjs(item['DocDate']);
            item['due'] = dayjs(item['DueDate']);
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchRequest(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryTransferRequests(${id})`);

  apiUrl.searchParams.append('$select', 'DocEntry,DocNum,DocDate,DueDate,FromWarehouse,ToWarehouse,Comments,DocumentStatus');

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  const lines = await fetchRequestItemsStock(sessionID, id);
  data['StockTransferLines'] = [];
  lines.forEach((line) => {
    let l = line['InventoryTransferRequests/StockTransferLines'];
    l['id'] = l['LineNum'];
    l['InStock'] = line['Items/ItemWarehouseInfoCollection']['InStock'];
    data['StockTransferLines'].push(l);
  });
  return data;
}

export async function fetchRequestItensBarcodes(sessionID, id) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryTransferRequests/StockTransferLines,Items)');
  let data = [];
  let repeat = true;

  const expand = [
    'InventoryTransferRequests/StockTransferLines($select=DocEntry,LineNum,ItemCode)',
    'Items($select=ItemCode,BarCode)',
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    "InventoryTransferRequests/StockTransferLines/ItemCode eq Items/ItemCode",
    `InventoryTransferRequests/StockTransferLines/DocEntry eq ${id}`,
  ];
  apiUrl.searchParams.append('$filter', filters.join(' and '));


  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item['InventoryTransferRequests/StockTransferLines'];
            line['id'] = line['LineNum'];
            line['BarCode'] = item['Items']['BarCode'];
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchStockTranfers(sessionID, requestID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(StockTransfers,StockTransfers/StockTransferLines)`);
  let data = [];
  let repeat = true;

  const expand = [
    'StockTransfers($select=DocEntry)',
    'StockTransfers/StockTransferLines($select=LineNum,ItemCode,ItemDescription,Quantity,WarehouseCode,FromWarehouseCode,BaseEntry,BaseLine,MeasureUnit,U_EASY_Colab)',
  ];

  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    "StockTransfers/DocEntry eq StockTransfers/StockTransferLines/DocEntry",
    `StockTransfers/StockTransferLines/BaseEntry eq ${requestID}`,
  ];

  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$orderby', 'StockTransfers/StockTransferLines/BaseLine asc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item['StockTransfers/StockTransferLines'];
            line['id'] = line['BaseLine'];
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchBatches(sessionID, itemCode, warehouseCode) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('itemWarehouseBatch')/List");
  apiUrl.searchParams.append('itemCode', `'${itemCode}'`);
  apiUrl.searchParams.append('whsCode', `'${warehouseCode}'`);
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
      Prefer: 'odata.maxpagesize=500'
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  return await response.json();
}

export async function fetchRequestItemsStock(sessionID, requestID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryTransferRequests/StockTransferLines,Items,Items/ItemWarehouseInfoCollection)');
  let data = [];
  let repeat = true;

  const expand = [
    'InventoryTransferRequests/StockTransferLines($select=DocEntry,ItemCode,LineNum,Quantity,MeasureUnit,FromWarehouseCode,WarehouseCode,ItemDescription,RemainingOpenQuantity, LineStatus)',
    'Items/ItemWarehouseInfoCollection($select=ItemCode,WarehouseCode,InStock)',
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    `InventoryTransferRequests/StockTransferLines/DocEntry eq ${requestID}`,
    "InventoryTransferRequests/StockTransferLines/ItemCode eq Items/ItemCode",
    "Items/ItemCode eq Items/ItemWarehouseInfoCollection/ItemCode",
    "Items/ItemWarehouseInfoCollection/WarehouseCode eq InventoryTransferRequests/StockTransferLines/FromWarehouseCode",
  ];
  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$orderby', 'InventoryTransferRequests/StockTransferLines/LineNum asc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function createStockTransfer(sessionID, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/StockTransfers`);

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return await response.json();
}

export async function updateTransferRequest(sessionID, docEntry, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryTransferRequests(${docEntry})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return null;
}
