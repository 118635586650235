import dayjs from 'dayjs';

export const statuses = [
  { simple: 'R', id: 'boposReleased', label: 'Liberada' },
  { simple: 'L', id: 'boposClosed', label: 'Fechada' },
  { simple: 'P', id: 'boposPlanned', label: 'Planejada' },
  { simple: 'C', id: 'boposCanceled', label: 'Cancelada' },
];

export async function fetchProductionOrders(sessionID, startDate = null, endDate = null, docNumber = null, description = null, status = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders');
  let data = [];
  let repeat = true;

  const select = [
    'AbsoluteEntry',
    'DocumentNumber',
    'ProductionOrderStatus',
    'ItemNo',
    'ProductDescription',
    'Project',
    'PostingDate',
    'DueDate',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [];
  if (docNumber) {
    filters.push(`contains(DocumentNumber,'${docNumber}')`);
  } else {
    if (startDate) {
      filters.push(`PostingDate ge '${startDate}'`);
    }
    if (endDate) {
      filters.push(`DueDate le '${endDate}'`);
    }
    if (description) {
      filters.push(`contains(ProductDescription,'${description}')`);
    }
    if (status) {
      filters.push(`ProductionOrderStatus eq '${status}'`);
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  apiUrl.searchParams.append('$orderby', 'AbsoluteEntry desc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsoluteEntry'];
            item['created'] = dayjs(item['PostingDate']);
            item['due'] = dayjs(item['DueDate']);
            item['Status'] = item['ProductionOrderStatus'] ? statuses.find(status => status.id === item['ProductionOrderStatus'])?.label : item['ProductionOrderStatus'];
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchInventoryGenExits(sessionID, docNumber = null, status = null, fromWarehouse = null, toWarehouse = null, comments = null, startDate = null, endDate = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryGenExits');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'DocEntry, DocNum,DocDate,DueDate,FromWarehouse,ToWarehouse,Comments,DocumentStatus');
  apiUrl.searchParams.append('$orderby', 'DocEntry desc');

  const filters = [];
  if (docNumber) {
    filters.push(`contains(DocNum,'${docNumber}')`);
  } else {
    if (status) {
      filters.push(`DocumentStatus eq '${status}'`);
    }
    if (fromWarehouse) {
      filters.push(`contains(FromWarehouse,'${String(fromWarehouse).toUpperCase()}')`);
    }
    if (toWarehouse) {
      filters.push(`contains(ToWarehouse,'${String(toWarehouse).toUpperCase()}')`);
    }
    if (comments) {
      filters.push(`contains(Comments,'${comments}')`);
    }
    if (startDate) {
      filters.push(`DueDate ge '${startDate}'`);
    }
    if (endDate) {
      filters.push(`DueDate le '${endDate}'`);
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['DocEntry'];
            item['Status'] = item['DocumentStatus'] ? statuses.find(status => status.id === item['DocumentStatus']).label : item['DocumentStatus'];
            item['created'] = dayjs(item['DocDate']);
            item['due'] = dayjs(item['DueDate']);
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchOrder(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders(${id})`);

  const select = [
    'AbsoluteEntry',
    'DocumentNumber',
    'ProductionOrderStatus',
    'ItemNo',
    'ProductDescription',
    'Project',
    'PostingDate',
    'DueDate',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  data['Item'] = String(data['ItemNo']).concat(' - ', data['ProductDescription']);
  const lines = await fetchOrderItemsStock(sessionID, id);
  data['ProductionOrderLines'] = [];
  lines.forEach((line) => {
    let l = line['ProductionOrders/ProductionOrderLines'];
    l['id'] = l['LineNumber'];
    l['MeasureUnit'] = line['Items']['InventoryUOM'];
    l['ItemName'] = line['Items']['ItemName'];
    l['InStock'] = line['Items/ItemWarehouseInfoCollection']['InStock'];
    data['ProductionOrderLines'].push(l);
  });
  return data;
}

export async function fetchOrderItemsStock(sessionID, orderID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(ProductionOrders/ProductionOrderLines,Items,Items/ItemWarehouseInfoCollection)');
  let data = [];
  let repeat = true;

  const expand = [
    'ProductionOrders/ProductionOrderLines($select=DocumentAbsoluteEntry,ItemNo,LineNumber,BaseQuantity,PlannedQuantity,IssuedQuantity,AdditionalQuantity,Warehouse,Project)',
    'Items($select=ItemCode,ItemName, InventoryUOM)',
    'Items/ItemWarehouseInfoCollection($select=ItemCode,WarehouseCode,InStock)',
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    `ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry eq ${orderID}`,
    "ProductionOrders/ProductionOrderLines/ItemNo eq Items/ItemCode",
    "Items/ItemCode eq Items/ItemWarehouseInfoCollection/ItemCode",
    "Items/ItemWarehouseInfoCollection/WarehouseCode eq ProductionOrders/ProductionOrderLines/Warehouse",
  ];
  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$orderby', 'ProductionOrders/ProductionOrderLines/LineNumber asc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchRequestItensBarcodes(sessionID, id) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryGenExits/StockTransferLines,Items)');
  let data = [];
  let repeat = true;

  const expand = [
    'InventoryGenExits/StockTransferLines($select=DocEntry,LineNum,ItemCode)',
    'Items($select=ItemCode,BarCode)',
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    "InventoryGenExits/StockTransferLines/ItemCode eq Items/ItemCode",
    `InventoryGenExits/StockTransferLines/DocEntry eq ${id}`,
  ];
  apiUrl.searchParams.append('$filter', filters.join(' and '));


  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item['InventoryGenExits/StockTransferLines'];
            line['id'] = line['LineNum'];
            line['BarCode'] = item['Items']['BarCode'];
            line['ItemName'] = item['Items']['ItemName'];
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchInventoryExits(sessionID, orderID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryGenExits,InventoryGenExits/DocumentLines)`);
  let data = [];
  let repeat = true;

  const expand = [
    'InventoryGenExits($select=DocEntry)',
    'InventoryGenExits/DocumentLines($select=LineNum,ItemCode,ItemDescription,Quantity,WarehouseCode,BaseEntry,BaseLine)',
  ];

  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    "InventoryGenExits/DocEntry eq InventoryGenExits/DocumentLines/DocEntry",
    `InventoryGenExits/DocumentLines/BaseEntry eq ${orderID}`,
  ];

  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$orderby', 'InventoryGenExits/DocumentLines/BaseLine asc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item['InventoryGenExits/DocumentLines'];
            line['id'] = line['BaseLine'];
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchBatches(sessionID, itemCode, warehouseCode) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('itemWarehouseBatch')/List");
  apiUrl.searchParams.append('itemCode', `'${itemCode}'`);
  apiUrl.searchParams.append('whsCode', `'${warehouseCode}'`);
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
      Prefer: 'odata.maxpagesize=500'
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  return await response.json();
}


export async function createInventoryExit(sessionID, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryGenExits`);

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return await response.json();
}
