import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Visibility } from '@mui/icons-material';

import { statuses } from './fetchData';

const today = new Date();

const ProductionOrdersTable = memo(function ProductionOrdersTable({ data, isLoading, onEdit }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  const isOverdue = (item) => item['Status'] == statuses[0].label  && item['due'] < today;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>OP</TableCell>
            <TableCell>Data Criação</TableCell>
            <TableCell>Data Início</TableCell>
            <TableCell>Data Vencimento</TableCell>
            <TableCell>Projeto</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundImage: isOverdue(item) ? 'linear-gradient(#F002, #F002)' : 'inherit',
              }}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Visibility /></IconButton></TableCell>
              <TableCell component="th" scope="row">
                {item.DocumentNumber}
              </TableCell>
              <TableCell>{item.created.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.start.format('DD/MM/YYYY')}</TableCell>
              <TableCell sx={isOverdue(item) ? {color: 'red'} : {}}>
                {item.due.format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>{item.Project}</TableCell>
              <TableCell>{item.ItemNo}</TableCell>
              <TableCell>{item.ProductDescription}</TableCell>
              <TableCell>{item.Status}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhuma Ordem de Produção encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ProductionOrdersTable;
