import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import dataGridText from "assets/dataGridText";
import {
  itemClasses, itemsGroupCodes, itemTypes, manufacturers,
  materialTypes, planningSystems, procurementMethods, treeTypes,
  boolTypes
} from "../fetchData";

export default function StepMap({ onChange, value }) {

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setData(value);
  }, [value]);

  useEffect(() => {
    let c = [
      { field: 'id', headerName: 'Código', width: 200, editable: false, },
      { field: 'ItemName', headerName: 'Nome', width: 250, editable: false, },
      { field: 'ForeignName', headerName: 'Descriçao Auxiliar', width: 300, editable: false, },
      { field: 'NCMCode', headerName: 'NCM', width: 200, editable: false, },
      { field: 'SalesUnit', headerName: 'UM Venda', width: 200, editable: false, },
      { field: 'SalesPackagingUnit', headerName: 'UM V Embalagem', width: 200, editable: false, },
      { field: 'InventoryUOM', headerName: 'UM Estoque', width: 200, editable: false, },
      { field: 'PurchaseUnit', headerName: 'UM Compra', width: 200, editable: false, },
      { field: 'PurchasePackagingUnit', headerName: 'UM C Embalagem', width: 200, editable: false, },
      { field: 'LeadTime', headerName: 'Lead Time', width: 200, editable: true, },
      { field: 'ManageSerialNumbers', headerName: 'Adm. Nº Série', width: 200, editable: true, type: 'singleSelect', valueOptions: boolTypes.map((b) => b.label) },
      { field: 'ManageBatchNumbers', headerName: 'Adm. Lote', width: 200, editable: true, type: 'singleSelect', valueOptions: boolTypes.map((b) => b.label) },
      { field: 'ItemClass', headerName: 'Classificação', width: 200, editable: true, type: 'singleSelect', valueOptions: itemClasses.map((c) => c.label) },
      { field: 'ItemType', headerName: 'Tipo', width: 200, editable: true, type: 'singleSelect', valueOptions: itemTypes.map((t) => t.label) },
      { field: 'MaterialType', headerName: 'Tipo do Material', width: 200, editable: true, type: 'singleSelect', valueOptions: materialTypes.map((m) => m.label) },
      { field: 'ItemsGroupCode', headerName: 'Grupo de Items', width: 200, editable: true, type: 'singleSelect', valueOptions: itemsGroupCodes.map((c) => c.label) },
      { field: 'PlanningSystem', headerName: 'Método de Planejamento', width: 200, editable: true, type: 'singleSelect', valueOptions: planningSystems.map((s) => s.label) },
      { field: 'ProcurementMethod', headerName: 'Método de Suprimento', width: 200, editable: true, type: 'singleSelect', valueOptions: procurementMethods.map((m) => m.label) },
      { field: 'Manufacturer', headerName: 'Fabricante', width: 300, editable: true, type: 'singleSelect', valueOptions: manufacturers.map((m) => m.label) },
      { field: 'TreeType', headerName: 'Estrutura de Produto', width: 200, editable: true, type: 'singleSelect', valueOptions: treeTypes.map((t) => t.label) },
      {
        field: 'status',
        headerName: 'Status',
        editable: false,
        width: 300,
        renderCell: (params) => {
          if (params.row.status === true) {
            return <Alert severity="success" variant="outlined">Salvo</Alert>;
          }
          else if (params.row.status) {
            return <Alert severity="error" variant="filled" style={{ overflow: 'auto' }}>Erro: {params.row.status}</Alert>;
          }
          return null;
        },
      }
    ];
    setColumns(c);
  }, [manufacturers]);

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          onProcessRowUpdateError={(error) => console.error(error)}
          columns={columns}
          isCellEditable={(params) => !params.row.saved}
          isRowSelectable={() => false}
          editMode="row"
          style={{ height: '50vh' }}
          localeText={dataGridText}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}
