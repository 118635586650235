import dayjs from "dayjs";

export const statuses = [
  { id: 'bost_Open', label: 'Aberta' },
  { id: 'bost_Close', label: 'Fechada' },
];

export async function fetchStockTransfers(sessionID, docNum = null, fromWarehouse = null, toWarehouse = null, comments = null, startDate = null, endDate = null) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('stockTransfersXEmployees')/List?docNum='%'&toWhs='%'&fromWhs='%'&comments='%'&startDate='2020-01-01'&endDate='2029-12-30'");
  let data = [];
  let repeat = true;

  if (docNum) {
    apiUrl.searchParams.set('docNum', "'%".concat(docNum, "%'"));
  } else if (comments) {
    apiUrl.searchParams.set('comments', "'%".concat(comments, "%'"));
  } else {
    if (startDate) {
      apiUrl.searchParams.set('startDate', "'".concat(startDate, "'"));
    }
    if (endDate) {
      apiUrl.searchParams.set('endDate', "'".concat(endDate, "'"));
    }
  }
  if (fromWarehouse) {
    apiUrl.searchParams.set('fromWhs', "'%".concat(String(fromWarehouse).toUpperCase(), "%'"));
  }
  if (toWarehouse) {
    apiUrl.searchParams.set('toWhs', "'%".concat(String(toWarehouse).toUpperCase(), "%'"));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
      },
    })
      .then(response => response.json())
      .then((json) => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item;
            line['id'] = item['DocEntry'];
            line['date'] = dayjs(item['DocDate'], 'YYYYMMDD');
            line['receiver'] = item['empID'] && String(item['empID']).concat(' - ', item['firstName'], ' ', item['lastName']);
            line['receivedDate'] = item['U_EASY_DtRec'] && dayjs(item['U_EASY_DtRec'], 'YYYYMMDD');
            data.push(line);
          });
        }
      })
      .catch((error) => {
        console.error('Erro:', error);
        return null;
      });
  }
  return data;
}

export async function fetchStockTransfer(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/StockTransfers(${id})`);

  const select = [
    'DocEntry',
    'DocNum',
    'DocDate',
    'Comments',
    'U_RecebidoPor',
    'U_EASY_DtRec',
    'FromWarehouse',
    'ToWarehouse',
  ];

  apiUrl.searchParams.set('$select', select.join(','));

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  if (data['U_RecebidoPor']) {
    data['receiver'] = await fetchEmployee(sessionID, data['U_RecebidoPor']);
  }
  return data;
}

async function fetchEmployee(sessionID, employeeID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo(${employeeID})`);

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  if (data) {
    return String(data['EmployeeID']).concat(' - ', data['FirstName'], ' ', data['LastName']);
  }
}

export async function fetchStockTransferLines(sessionID, docEntry) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('stockTransferLinesXEmployees')/List");
  let data = [];
  let repeat = true;

  apiUrl.searchParams.set('docEntry', docEntry);

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
      },
    })
      .then(response => response.json())
      .then((json) => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item;
            line['id'] = item['LineNum'];
            line['employee'] = item['empID'] && String(item['empID']).concat(' - ', item['firstName'], ' ', item['lastName']);
            line['MeasureUnit'] = item['unitMsr'];
            data.push(line);
          });
        }
      })
      .catch((error) => {
        console.error('Erro:', error);
        return null;
      });
  }
  return data;
}

export async function patchStockTransfer(sessionID, transferID, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/StockTransfers(${transferID})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
}
