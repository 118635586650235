import React, { memo } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ItemTable = memo(function ItemTable({ data, isLoading }) {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Descrição Auxiliar</TableCell>
            <TableCell>Estrutura</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
            >
              <TableCell component="th" scope="row">
                {item.id}
              </TableCell>
              <TableCell>{item.materialType}</TableCell>
              <TableCell>{item.ItemName}</TableCell>
              <TableCell>{item.ForeignName}</TableCell>
              <TableCell>{item.treeType}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                Nenhum Item encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}, (prevProps, nextProps) => prevProps.isLoading === nextProps.isLoading && prevProps.data.length === nextProps.data.length);

export default ItemTable;
