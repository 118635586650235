
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchInventoryTransferRequests, statuses } from '../fetchData';
import Cookies from 'js-cookie';
import dataGridText from 'assets/dataGridText';
import { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

export default function StepSelect({ onChange }) {
  const columns = [
    { field: 'index', headerName: 'Linha', width: 50, editable: false, },
    { field: 'DocNum', headerName: 'ID', width: 50, editable: false, },
    { field: 'FromWarehouse', headerName: 'De', width: 100, editable: false, },
    { field: 'ToWarehouse', headerName: 'Para', width: 100, editable: false, },
    { field: 'DocDate', headerName: 'Abertura', width: 150, type: 'date', editable: false, },
    { field: 'DueDate', headerName: 'Vencimento', width: 150, type: 'date', editable: false, },
    { field: 'Comments', headerName: 'Comentários', width: 400, editable: false, },
  ];

  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);

  const [fetchingRequests, setFetchingRequests] = useState(false);

  const [docNumber, setDocNumber] = useState('');
  const [fromWarehouse, setFromWarehouse] = useState('');
  const [toWarehouse, setToWarehouse] = useState('');
  const [comments, setComments] = useState('');
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));

  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingRequests(true);
    fetchInventoryTransferRequests(
      sessionID,
      docNumber,
      statuses[0].id,
      fromWarehouse,
      toWarehouse,
      comments,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
    ).then((rows) => {
      if (rows != null) {
        const lines = [];
        rows.forEach((row, index) => {
          lines.push({
            ...row,
            index: index,
            DocDate: dayjs(row.DocDate).toDate(),
            DueDate: dayjs(row.DueDate).toDate(),
          });
        });
        setData(lines);
      }
    }).finally(() => {
      if (!debounceTimer) {
        setFetchingRequests(false);
      }
    });
  }, 1000), [docNumber, fromWarehouse, toWarehouse, comments, startDate, endDate]);

  useEffect(() => {
    onChange(data.filter((row) => selection.includes(row.id)));
  }, [selection]);

  const handleChangeDocNumber = (e) => {
    setDocNumber(e.target.value);
  }

  const handleChangeFromWarehouse = (e) => {
    setFromWarehouse(e.target.value);
  }

  const handleChangeToWarehouse = (e) => {
    setToWarehouse(e.target.value);
  }

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  const handleChangeSelection = (selectionModel) => {
    setSelection(selectionModel);
  }

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="docNumber" label="Número da Picking"
                value={docNumber} onChange={handleChangeDocNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="fromWarehouse" label="De" value={fromWarehouse}
                onChange={handleChangeFromWarehouse}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="toWarehouse" label="Para"
                value={toWarehouse} onChange={handleChangeToWarehouse}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="comments" label="OP ou Projeto"
                value={comments} onChange={handleChangeComments}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker id="data-inicio" label="Data Inicial" onChange={handleChangeStartDate} value={startDate} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          columns={columns}
          rowSelectionModel={selection}
          onRowSelectionModelChange={handleChangeSelection}
          style={{ height: '50vh' }}
          loading={fetchingRequests}
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnSorting
          localeText={dataGridText}
        />
      </Grid>
    </Grid>
  );
}
