import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import {
  itemClasses, itemTypes, materialTypes, itemsGroupCodes,
  planningSystems, procurementMethods, treeTypes, manufacturers,
  boolTypes,
} from './fetchData';

const header = [
  'ItemCode',
  'ItemName',
  'ForeignName',
  'NCMCode',
  'SalesUnit',
  'SalesPackagingUnit',
  'InventoryUOM',
  'PurchaseUnit',
  'PurchasePackagingUnit',
  'LeadTime',
  'ManageSerialNumbers',
  'ManageBatchNumbers',
  'ItemClass',
  'ItemType',
  'MaterialType',
  'ItemsGroupCode',
  'PlanningSystem',
  'ProcurementMethod',
  'Manufacturer',
  'TreeType',
];

const cabecalho = [
  'Código',
  'Nome',
  'Descrição Auxiliar',
  'NCM',
  'UM Venda',
  'UM V Embalagem',
  'UM Estoque',
  'UM Compra',
  'UM C Embalagem',
  'Lead Time',
  'Adm. Nº Série',
  'Adm. Lote',
  'Classificação',
  'Tipo',
  'Tipo do Material',
  'Grupo de Items',
  'Método de Planejamento',
  'Método de Suprimento',
  'Fabricante',
  'Estrutura de Produto',
];

export async function exportTemplate(rows = null) {
  const templateUrl = '/TemplateItem.xlsx';

  const response = await fetch(templateUrl);
  const data = await response.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(data);

  const manufacturersSheet = workbook.getWorksheet('Manufacturer');

  manufacturers.forEach((manufacturer) => {
    manufacturersSheet.addRow([manufacturer.label]);
  });

  const listValidations = [
    'ItemClass',
    'ItemType',
    'MaterialType',
    'ItemsGroupCode',
    'PlanningSystem',
    'ProcurementMethod',
    'Manufacturer',
    'TreeType',
  ];

  const itemsSheet = workbook.getWorksheet('Itens');

  listValidations.forEach((key) => {
    const index = header.indexOf(key);
    const letter = String.fromCharCode(65 + index);
    itemsSheet.dataValidations.add(`${letter}2:${letter}9999`, {
      type: 'list',
      allowBlank: true,
      formulae: [`${key}!$A:$A`],
    });
  });

  const boolValidations = [
    'ManageSerialNumbers',
    'ManageBatchNumbers',
  ];

  boolValidations.forEach((key) => {
    const index = header.indexOf(key);
    const letter = String.fromCharCode(65 + index);
    itemsSheet.dataValidations.add(`${letter}2:${letter}9999`, {
      type: 'list',
      allowBlank: true,
      formulae: ['"Sim, Não"'],
    });
  });

  if (rows) {
    rows.forEach((item) => {
      const row = [
        item['ItemCode'],
        item['ItemName'],
        item['ForeignName'],
        item['NCMCode'],
        item['SalesUnit'],
        item['SalesPackagingUnit'],
        item['InventoryUOM'],
        item['PurchaseUnit'],
        item['PurchasePackagingUnit'],
        item['LeadTime'],
        boolTypes.find((b) => b.id == item['ManageSerialNumbers'])?.label,
        boolTypes.find((b) => b.id == item['ManageBatchNumbers'])?.label,
        itemClasses.find((c) => c.id == item['ItemClass'])?.label,
        itemTypes.find((t) => t.id == item['ItemType'])?.label,
        materialTypes.find((t) => t.id == item['MaterialType'])?.label,
        itemsGroupCodes.find((c) => c.id == item['ItemsGroupCode'])?.label,
        planningSystems.find((s) => s.id == item['PlanningSystem'])?.label,
        procurementMethods.find((m) => m.id == item['ProcurementMethod'])?.label,
        manufacturers.find((m) => m.id == item['Manufacturer'])?.label,
        treeTypes.find((t) => t.id == item['TreeType'])?.label,
      ];
      itemsSheet.addRow(row);
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer]),
    dayjs().format('YYYY-MM-DD-HH-mm').concat('-', 'TemplateItem.xlsx')
  );
}

export async function importFromTemplate(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => importCallback(e, resolve, reject);
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

async function importCallback(e, resolve, reject) {
  const data = new Uint8Array(e.target.result);
  const workbook = XLSX.read(data, { type: 'array', cellDates: true });
  if (!checkWorkbook(workbook)) {
    return reject('Planilha inválida! Selecione uma planilha válida.');
  }
  try {
    const data = extractData(workbook);
    console.log(data);
    return resolve(data);
  } catch (error) {
    return reject(error);
  }
}

function checkWorkbook(workbook) {
  const w = workbook.Sheets[workbook.SheetNames[0]];
  const j = XLSX.utils.sheet_to_json(w, { header: 1 });
  if (j[0].toString() === cabecalho.toString()) {
    return true;
  }
  return false;
}

function extractData(workbook) {
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  let rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  const data = [];
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    if (row.length === 0 || !row[0]) {
      continue;
    }
    const item = {};
    header.forEach((key, index) => {
      item[key] = row[index];
    });
    item['id'] = item['ItemCode'];
    data.push(item);
  }
  return data;
}
