import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Visibility } from '@mui/icons-material';

const ProductTreeTable = memo(function InventoryGenExitsTable({ data, onEdit, isLoading }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Código</TableCell>
            <TableCell>Projeto</TableCell>
            <TableCell>Depósito</TableCell>
            <TableCell>Descrição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Visibility /></IconButton></TableCell>
              <TableCell component="th" scope="row">
                {item.id}
              </TableCell>
              <TableCell>{item.Project}</TableCell>
              <TableCell>{item.Warehouse}</TableCell>
              <TableCell>{item.ProductDescription}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhuma Estrutura de Produto encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ProductTreeTable;
