import React, { useEffect, useState } from 'react';
import {
  Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
  Grid, TextField,
} from '@mui/material';
import Cookies from 'js-cookie';

import { useStatus } from '../../hooks/useStatus';
import { roles, fetchEmployee, createEmployee, deleteEmployee, editEmployee } from './fetchData';

const EmployeeFormModal = ({ open, onClose, onSubmit, id }) => {

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingEmployee, setFetchingEmployee] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [job, setJob] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [role, setRole] = useState(null);
  const [batch, setBatch] = useState(false);
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (id) {
      setFetchingEmployee(true);
      const sessionID = Cookies.get('sessionID');
      fetchEmployee(sessionID, id).then((response) => {
        if (response) {
          setFirstName(response.FirstName);
          setMiddleName(response.MiddleName);
          setLastName(response.LastName);
          setEmail(response.eMail);
          setJob(response.JobTitle);
          setCostCenter(response.CostCenterCode);
          setRole(roles.find((i) => i.value === response.U_Portal_Role));
          setBatch(response.U_EASY_ApontaEmLote === 'Y');
          setActive(response.Active === 'tYES');
        }
      }).catch((error) => {
        console.error(error);
        toggleStatus('Erro ao buscar o colaborador', error, 'error');
      }).finally(() => {
        setFetchingEmployee(false);
      });
    }
  }, [id]);

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  }

  const handleChangeMiddleName = (e) => {
    setMiddleName(e.target.value);
  }

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleChangeJob = (e) => {
    setJob(e.target.value);
  }

  const handleChangeCostCenter = (e) => {
    setCostCenter(e.target.value);
  }

  const handleChangeRole = (e, v) => {
    e.preventDefault();
    setRole(v);
  }

  const handleChangeBatch = (e) => {
    setBatch(e.currentTarget.checked);
  }

  const handleChangeActive = (e) => {
    setActive(e.currentTarget.checked);
  }

  const handleClose = () => {
    if (id !== null) {
      handleReset();
    }
    onClose();
  }

  const handleReset = () => {
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setEmail('');
    setJob('');
    setCostCenter('');
    setRole(null);
    setBatch(false);
    setActive(true);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  }

  const handleCreate = () => {
    const sessionID = Cookies.get('sessionID');
    const data = {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      eMail: email,
      JobTitle: job,
      CostCenterCode: costCenter,
      U_Portal_Role: role.value,
      U_EASY_ApontaEmLote: batch ? 'Y' : 'N',
      Active: active ? 'tYES' : 'tNO',
    };
    setSaving("Salvando");
    createEmployee(sessionID, data).then((response) => {
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar o colaborador', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const handleDelete = () => {
    const sessionID = Cookies.get('sessionID');
    setSaving("Excluindo");
    deleteEmployee(sessionID, id).then((response) => {
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao excluir o colaborador', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  };

  const handleEdit = () => {
    const sessionID = Cookies.get('sessionID');
    const data = {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      eMail: email,
      JobTitle: job,
      CostCenterCode: costCenter,
      U_Portal_Role: role.value,
      U_EASY_ApontaEmLote: batch ? 'Y' : 'N',
      Active: active ? 'tYES' : 'tNO',
    };
    setSaving("Editando");
    editEmployee(sessionID, id, data).then((response) => {
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      console.log('AAAAAAAAAAAAAAAAAA');
      toggleStatus('Erro ao editar o colaborador', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        component: 'form',
        onReset: handleCancel,
        onSubmit: handleSubmit,
        id: 'log-prod-form'
      }}
    >
      <DialogTitle>{id ? 'Editar' : 'Adicionar'} Colaborador</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Nome"
              onChange={handleChangeFirstName}
              required
              value={firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nome do Meio"
              onChange={handleChangeMiddleName}
              value={middleName}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Sobrenome"
              onChange={handleChangeLastName}
              required
              value={lastName}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              label="Email"
              onChange={handleChangeEmail}
              required
              type='email'
              value={email}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Cargo"
              onChange={handleChangeJob}
              required
              value={job}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Centro de Custo"
              onChange={handleChangeCostCenter}
              required
              value={costCenter}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              onChange={handleChangeRole}
              options={roles}
              renderInput={(params) => <TextField {...params} label="Permissão" required />}
              value={role}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Checkbox checked={batch} onChange={handleChangeBatch} />}
              label="Apontamento em Lote"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={<Checkbox checked={active} onChange={handleChangeActive} />}
              label="Ativo"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={handleDelete}
          variant="contained"
          size="large"
          sx={id == null ? { display: 'none' } : {}}
        >Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
        <Button
          variant="contained"
          size="large"
          type="submit"
        >Salvar</Button>
      </DialogActions>
      <Backdrop open={saving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingEmployee} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

EmployeeFormModal.defaultProps = {
  open: false,
  onClose: () => { },
  id: null,
};

export default EmployeeFormModal;
