import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, styled, Typography } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { importFromTemplate } from '../exportData';

export default function StepImport({ onChange, value }) {

  const [fileInfo, setFileInfo] = useState('Selecione o arquivo para importar');

  const [fetchingFile, setFetchingFile] = useState(false);

  const fileInputRef = React.createRef();

  useEffect(() => {
    if (value) {
      setFileInfo(String(value.name).concat(' - ', value.lastModifiedDate.toLocaleString()));
    }
  }, [value]);

  const handleResetFile = () => {
    setFileInfo('Selecione o arquivo para importar');
    fileInputRef.current.value = '';
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFetchingFile(true);
      setFileInfo('Importando arquivo e conferindo por conflitantes...');
      setTimeout(() => {
        importFromTemplate(file)
          .then((data) => {
            onChange({
              name: file.name,
              lastModifiedDate: file.lastModifiedDate,
              data: data,
            });
          })
          .catch((error) => {
            console.error(error);
            setFileInfo(String(error));
          })
          .finally(() => {
            setFetchingFile(false);
          });
      }, 500);
    }
  }

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '40vh',
    }}>
      <Typography variant="h6" gutterBottom>{fileInfo}</Typography>
      {!fetchingFile ? (
        <Button
          component="label"
          role={undefined}
          size="large"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          onClick={handleResetFile}
        >
          Importar Arquivo
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx"
            onChange={handleChangeFile}
            ref={fileInputRef}
          />
        </Button>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
