export default {
  // Raiz
  noRowsLabel: 'Nenhuma linha',
  noResultsOverlayLabel: 'Nenhum resultado encontrado.',

  // Texto do botão da barra de ferramentas de seleção de densidade
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortável',

  // Texto do botão da barra de ferramentas de seleção de colunas
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar colunas',

  // Texto do botão da barra de ferramentas de filtros
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Campo de filtro rápido da barra de ferramentas
  toolbarQuickFilterPlaceholder: 'Buscar...',
  toolbarQuickFilterLabel: 'Buscar',
  toolbarQuickFilterDeleteIconLabel: 'Limpar',

  // Texto do botão da barra de ferramentas de exportação
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Baixar como CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarExportExcel: 'Baixar como Excel',

  // Texto de gerenciamento de colunas
  columnsManagementSearchTitle: 'Buscar',
  columnsManagementNoColumns: 'Nenhuma coluna',
  columnsManagementShowHideAllText: 'Mostrar/Ocultar Tudo',
  columnsManagementReset: 'Redefinir',

  // Texto do painel de filtro
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelRemoveAll: 'Remover todos',
  filterPanelDeleteIconLabel: 'Excluir',
  filterPanelLogicOperator: 'Operador lógico',
  filterPanelOperator: 'Operador',
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor do filtro',

  // Texto dos operadores de filtro
  filterOperatorContains: 'contém',
  filterOperatorDoesNotContain: 'não contém',
  filterOperatorEquals: 'igual a',
  filterOperatorDoesNotEqual: 'diferente de',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'não é',
  filterOperatorAfter: 'depois de',
  filterOperatorOnOrAfter: 'em ou depois de',
  filterOperatorBefore: 'antes de',
  filterOperatorOnOrBefore: 'em ou antes de',
  filterOperatorIsEmpty: 'está vazio',
  filterOperatorIsNotEmpty: 'não está vazio',
  filterOperatorIsAnyOf: 'é um de',
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Texto dos operadores de filtro do cabeçalho
  headerFilterOperatorContains: 'Contém',
  headerFilterOperatorDoesNotContain: 'Não contém',
  headerFilterOperatorEquals: 'Igual a',
  headerFilterOperatorDoesNotEqual: 'Diferente de',
  headerFilterOperatorStartsWith: 'Começa com',
  headerFilterOperatorEndsWith: 'Termina com',
  headerFilterOperatorIs: 'É',
  headerFilterOperatorNot: 'Não é',
  headerFilterOperatorAfter: 'Depois de',
  headerFilterOperatorOnOrAfter: 'Em ou depois de',
  headerFilterOperatorBefore: 'Antes de',
  headerFilterOperatorOnOrBefore: 'Em ou antes de',
  headerFilterOperatorIsEmpty: 'Está vazio',
  headerFilterOperatorIsNotEmpty: 'Não está vazio',
  headerFilterOperatorIsAnyOf: 'É um de',
  'headerFilterOperator=': 'Igual a',
  'headerFilterOperator!=': 'Diferente de',
  'headerFilterOperator>': 'Maior que',
  'headerFilterOperator>=': 'Maior ou igual a',
  'headerFilterOperator<': 'Menor que',
  'headerFilterOperator<=': 'Menor ou igual a',

  // Texto de valores de filtro
  filterValueAny: 'qualquer',
  filterValueTrue: 'verdadeiro',
  filterValueFalse: 'falso',

  // Texto do menu de colunas
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar colunas',
  columnMenuManageColumns: 'Gerenciar colunas',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Ocultar coluna',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar por ASC',
  columnMenuSortDesc: 'Ordenar por DESC',

  // Texto do cabeçalho de colunas
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Texto do rodapé de linhas selecionadas
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Texto do rodapé de quantidade total de linhas
  footerTotalRows: 'Total de Linhas:',

  // Texto do rodapé de quantidade total de linhas visíveis
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Texto da seleção de caixas de seleção
  checkboxSelectionHeaderName: 'Seleção de caixa de seleção',
  checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
  checkboxSelectionUnselectAllRows: 'Desmarcar todas as linhas',
  checkboxSelectionSelectRow: 'Selecionar linha',
  checkboxSelectionUnselectRow: 'Desmarcar linha',

  // Texto de células booleanas
  booleanCellTrueLabel: 'sim',
  booleanCellFalseLabel: 'não',

  // Texto de ações de células
  actionsCellMore: 'mais',

  // Texto de fixação de colunas
  pinToLeft: 'Fixar à esquerda',
  pinToRight: 'Fixar à direita',
  unpin: 'Desfixar',

  // Dados de árvore
  treeDataGroupingHeaderName: 'Agrupar',
  treeDataExpand: 'ver filhos',
  treeDataCollapse: 'ocultar filhos',

  // Agrupamento de colunas
  groupingColumnHeaderName: 'Agrupar',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Parar de agrupar por ${name}`,

  // Master/detail
  detailPanelToggle: 'Alternar painel de detalhes',
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Recolher',
  
  // Row reordering text
  rowReorderingHeaderName: 'Reordenar linhas',
  
  // Aggregation
  aggregationMenuItemHeader: 'Agregação',
  aggregationFunctionLabelSum: 'soma',
  aggregationFunctionLabelAvg: 'média',
  aggregationFunctionLabelMin: 'mínimo',
  aggregationFunctionLabelMax: 'máximo',
  aggregationFunctionLabelSize: 'tamanho',
};