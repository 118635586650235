import React, { useEffect, useState } from 'react';
import {
  Alert, AppBar, Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, Grid, IconButton, InputLabel,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { fetchWarehouses, createStockTransfer } from './fetchData';
import TransferSubForm from './subform';
import ItemsTable from './subtable';

const StockTransferDetailModal = ({ open, onClose, onSubmit, id }) => {

  const [fromWarehouse, setFromWarehouse] = useState(null);
  const [toWarehouse, setToWarehouse] = useState(null);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [comments, setComments] = useState('');

  const [warehouses, setWarehouses] = useState([]);

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [fetchingWarehouses, setFetchingWarehouses] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [showEdit, setShowEdit] = useState(false);


  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingWarehouses(true);
    fetchWarehouses(sessionID)
      .then((data) => {
        if (data != null) {
          setWarehouses(data);
        }
      })
      .finally(() => {
        setFetchingWarehouses(false);
      });
  }, []);

  useEffect(() => {
    setRefresh(false);
  }, [id, refresh]);

  const handleChangeFromWarehouse = (e, value) => {
    setFromWarehouse(value);
  }

  const handleChangeToWarehouse = (e, value) => {
    setToWarehouse(value);
  }

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  }

  const handleReset = () => {
    setFromWarehouse(null);
    setToWarehouse(null);
    setItems([]);
    setComments('');
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving('Salvando transferência');
    const sessionID = Cookies.get('sessionID');
    const data = {
      FromWarehouse: fromWarehouse.value,
      ToWarehouse: toWarehouse.value,
      DocDate: date,
      Comments: comments,
      StockTransferLines: items.map((line) => ({ ...line, ItemLabel: undefined })),
    };
    createStockTransfer(sessionID, data).then((response) => {
      console.log(response);
      handleReset();
      onSubmit();
      onClose();
      toggleStatus('Transferência salva com sucesso!', `Criada transferência Nº ${response['DocNum']}`, 'success');
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar transferências!', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const handleAddForm = () => {
    setEditIndex(null);
    setShowEdit(true);
  }

  const handleEditForm = (index) => {
    setEditIndex(index);
    setShowEdit(true);
  }

  const handleCloseForm = () => {
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleFormSubmit = (line) => {
    if (line['BatchNumbers'].length > 0) {
      setItems([...items, line]);
    }
    handleCloseForm();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        onReset={handleCancel}
        id="stock-transfer-form"
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Nova Transferência de Estoque
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                fullWidth
                label="De"
                options={warehouses}
                getOptionLabel={(option) => option.label}
                value={fromWarehouse}
                onChange={handleChangeFromWarehouse}
                renderInput={(params) => <TextField {...params} label="De" required />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                fullWidth
                label="Para"
                options={warehouses}
                getOptionLabel={(option) => option.label}
                value={toWarehouse}
                onChange={handleChangeToWarehouse}
                renderInput={(params) => <TextField {...params} label="Para" required />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                disabled
                label="Data"
                type="date"
                value={date}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentários"
                multiline
                rows={2}
                value={comments}
                onChange={handleChangeComments}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" mt={2}>Materiais</Typography>
              <ItemsTable disabled={fromWarehouse === null} onAdd={handleAddForm} lines={items} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
          >Salvar</Button>
        </DialogActions>
      </Box>
      <TransferSubForm open={showEdit} onClose={handleCloseForm} onSubmit={handleFormSubmit} warehouse={fromWarehouse} />
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingWarehouses} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog >
  );
}

StockTransferDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

export default StockTransferDetailModal;

