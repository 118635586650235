import React, { useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, TextField, Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { useStatus } from '../../hooks/useStatus';
import { createInventoryExit, retrabalhos } from './fetchData';

const LogProdFormModal = ({ open, onClose, onSubmit, employees, productionOrders, fixedEmployee }) => {

  const [employee, setEmployee] = useState(fixedEmployee);
  const [productionOrder, setProductionOrder] = useState(null);
  const [productionOrderLine, setProductionOrderLine] = useState(null);
  const [date, setDate] = useState(dayjs());
  const [holiday, setHoliday] = useState(false);
  const [retrabalho, setRetrabalho] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [observations, setObservations] = useState('');

  const [saving, setSaving] = useState(false);

  const { toggleStatus } = useStatus();

  useEffect(() => {
    if (fixedEmployee) {
      setEmployee(fixedEmployee);
    }
  }, [fixedEmployee]);

  const handleChangeEmployee = (e, value) => {
    e.preventDefault();
    setEmployee(value);
  }

  const handleChangeProductionOrder = (e, value) => {
    e.preventDefault();
    setProductionOrder(value);
    setProductionOrderLine(null);
    setDate(dayjs());
  }

  const handleChangeProductionOrderLine = (e, value) => {
    e.preventDefault();
    setProductionOrderLine(value);
  }

  const handleChangeDate = (value) => {
    setDate(value);
  }

  const handleChangeHoliday = (e) => {
    setHoliday(e.currentTarget.checked);
  }

  const handleChangeRetrabalho = (e, value) => {
    e.preventDefault();
    setRetrabalho(value);
  }

  const handleChangeStartTime = (value) => {
    setStartTime(value);
  }

  const handleChangeEndTime = (value) => {
    setEndTime(value);
  }

  const handleChangeObservations = (e) => {
    setObservations(e.target.value);
  }

  const handleReset = () => {
    if (!fixedEmployee) {
      setEmployee(null);
    }
    setProductionOrder(null);
    setProductionOrderLine(null);
    setDate(dayjs());
    setHoliday(false);
    setRetrabalho(null);
    setObservations('');
    setStartTime(null);
    setEndTime(null);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const sessionID = Cookies.get('sessionID');
    const data = {
      EmployeeID: employee.EmployeeID,
      ProductionOrder: productionOrder,
      ProductionOrderLine: productionOrderLine,
      Date: date.format('YYYY-MM-DD'),
      StartTime: startTime,
      EndTime: endTime,
      Holiday: holiday ? 'tYES' : 'tNO',
      Rework: retrabalho,
      Observations: observations,
    };
    setSaving(true);
    createInventoryExit(sessionID, data).then((response) => {
      console.log(response);
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar o apontamento', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const isInvalid = () => (
    startTime === null || endTime === null || endTime <= startTime || !startTime.isValid()
    || !endTime.isValid() || date === null || productionOrder === null || !date.isValid()
    || date < dayjs(productionOrder.StartDate) || date > dayjs()
  )
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleCancel,
        onSubmit: handleSubmit,
        id: 'log-prod-form'
      }}
    >
      <DialogTitle>Apontamento de Fábrica</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Autocomplete
              disabled={fixedEmployee !== null}
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeEmployee}
              options={employees}
              renderInput={(params) => <TextField {...params} label="Colaborador" required />}
              value={employee}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeProductionOrder}
              options={productionOrders}
              renderInput={(params) => <TextField {...params} label="Ordem Produção" required />}
              value={productionOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={productionOrder === null}
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeProductionOrderLine}
              options={productionOrder?.ProductionOrderLines || []}
              renderInput={(params) =>
                <TextField {...params}
                  label="Serviço Planejado"
                  helperText={productionOrder === null ? "Selecione uma OP" : " "}
                  required
                />
              }
              value={productionOrderLine}
            />
          </Grid>
          <Grid item xs={12}>
            <Box color="#0006" display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="subtitle2">Horas Planejadas:</Typography>
              <Typography variant="body2">{(productionOrderLine?.PlannedQuantity / 60 || 0).toFixed(2)}</Typography>
              <Typography variant="subtitle2">Horas Trabalhadas:</Typography>
              <Typography variant="body2">{(productionOrderLine?.IssuedQuantity / 60 || 0).toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  id="date"
                  label="Data"
                  disabled={productionOrder === null}
                  onChange={handleChangeDate}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  value={date}
                  minDate={dayjs(productionOrder?.StartDate)}
                  disableFuture
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={<Checkbox checked={holiday} color="warning" onChange={handleChangeHoliday} />}
              label="Feriado"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              fullWidth
              onChange={handleChangeRetrabalho}
              options={retrabalhos}
              renderInput={(params) => <TextField {...params} label="Retrabalho" />}
              value={retrabalho}
            />
            <FormHelperText error>Somente em caso de Retrabalho!</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <TimePicker
                  id="start-time"
                  label="Hora Início"
                  onChange={handleChangeStartTime}
                  value={startTime}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                />
                <FormHelperText>{startTime?.format('hh:mm')}</FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <TimePicker
                  id="end-time"
                  label="Hora Fim"
                  disabled={startTime === null}
                  onChange={handleChangeEndTime}
                  value={endTime}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  minTime={startTime}
                />
                <FormHelperText>{endTime?.format('hh:mm')}</FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição das atividades"
              multiline
              onChange={handleChangeObservations}
              value={observations}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
        <Button
          disabled={isInvalid()}
          color="primary"
          variant="contained"
          size="large"
          type="submit"
        >Salvar</Button>
      </DialogActions>
      <Backdrop open={saving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Alert severity="info">Salvando...</Alert>
      </Backdrop>
    </Dialog>
  );
}

LogProdFormModal.defaultProps = {
  open: false,
  onClose: () => { },
  employees: [],
  productionOrders: [],
  employee: null,
};

export default LogProdFormModal;
