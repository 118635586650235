import { DataGrid } from '@mui/x-data-grid';
import dataGridText from 'assets/dataGridText';

const columns = [
  { field: 'id', headerName: 'Nº', width: 50, editable: false, },
  { field: 'ItemNo', headerName: 'Item', width: 150, editable: false, },
  { field: 'Warehouse', headerName: 'Depósito', width: 100, editable: false, },
  { field: 'PlannedQuantity', headerName: 'Planejado', width: 100, editable: false, },
  { field: 'IssuedQuantity', headerName: 'Produzido', width: 100, editable: false, },
  { field: 'ItemName', headerName: 'Descrição', width: 400, editable: false, },
];

function ItemsTable({ data }) {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      style={{ height: '50vh' }}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSorting
      localeText={dataGridText}
    />
  );
}

export default ItemsTable;
