import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Step, StepLabel, Stepper,
} from '@mui/material';
import Cookies from 'js-cookie';

import { createProductTree, fetchProductTreesCodes, updateProductTree } from '../fetchData'
import StepMap from './stepMap';
import StepImport from './stepImport';
import StepSave from './stepSave';
import StepFinish from './stepFinish';

function ImportWizard({ open, onClose, onSubmit }) {

  const steps = [
    'Importe o arquivo',
    'Mapeie os campos',
    'Salve as estruturas',
    'Finalizando a importação',
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [errorStep, setErrorStep] = useState(null);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [trees, setTrees] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [fetchingTrees, setFetchingTrees] = useState(false);

  const handleChangeData = (value) => {
    const rows = {};
    for (let index = 0; index < value.length; index++) {
      const row = value[index];
      const line = {
        ItemCode: row.itemCode,
        ItemType: row.itemType === 'Item' ? 'pit_Item' : 'pit_Resource',
        Warehouse: row.warehouse,
        Quantity: row.quantity,
        Project: row.project,
        DistributionRule: row.distributionRule,
      };
      if (rows[row.treeCode]) {
        rows[row.treeCode].lines += 1;
        rows[row.treeCode].ProductTreeLines.push(line);
      } else {
        rows[row.treeCode] = {
          id: row.treeCode,
          TreeCode: row.treeCode,
          project: row.project,
          warehouse: row.warehouse,
          distributionRule: row.distributionRule,
          ProductTreeLines: [line],
          lines: 1,
          status: row.status,
          overwrite: null,
        };
      }
      row.line = rows[row.treeCode].lines;
    }

    setFetchingTrees(true);
    const sessionID = Cookies.get('sessionID');
    fetchProductTreesCodes(
      sessionID,
      Object.keys(rows)
    ).then((data) => {
      data.forEach((row) => {
        console.log(row)
        if (rows[row.TreeCode]) {
          rows[row.TreeCode].overwrite = false;
        }
      })
    }).catch((error) => {
      console.error(error);
    }).finally(() => setFetchingTrees(false));

    setTrees(rows);
    setData(value);

    if (errorStep === activeStep) {
      setErrorStep(null);
    }
  }

  const handleChangeFile = (value) => {
    setFile(value);
    if (value.data) {
      handleChangeData(value.data);
    }
  }

  const handleChangeTrees = (value) => {
    const rows = { ...trees };
    value.forEach((row) => {
      rows[row.TreeCode] = row;
    });
    if (errorStep === activeStep) {
      setErrorStep(null);
    }
    setTrees(rows);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setData([]);
    setErrorStep(null);
    setFile(null);
    setTrees({});
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    handleClose(e);
  }

  const handleClose = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data);
    handleReset();
    handleClose(e);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let error = false;
    const sessionID = Cookies.get('sessionID');
    for (const code in trees) {
      const row = trees[code];
      if (row.status === true || row.overwrite === false) {
        continue;
      }
      row.status = false;
      setTrees(trees);

      if (row.overwrite === true) {
        let sucess = await updateProductTree(
          sessionID,
          row.TreeCode,
          {
            TreeCode: row.TreeCode,
            TreeType: "iProductionTree",
            Quantity: 1,
            Project: row.project,
            Warehouse: row.warehouse,
            DistributionRule: row.distributionRule,
            ProductTreeLines: row.ProductTreeLines,
          }
        ).then(() => {
          row.status = true;
          setTrees(trees);
          return true;
        }).catch((error) => {
          console.error(error);
          if (typeof error === 'string') {
            row.status = error;
          } else {
            row.status = error.error.message.value;
          }
          setTrees(trees);
          setErrorStep(1);
        });
        if (!sucess) {
          error = true;
        }
      } else {
        let sucess = await createProductTree(
          sessionID,
          {
            TreeCode: row.TreeCode,
            TreeType: "iProductionTree",
            Quantity: 1,
            Project: row.project,
            Warehouse: row.warehouse,
            DistributionRule: row.distributionRule,
            ProductTreeLines: row.ProductTreeLines,
          }
        ).then(() => {
          row.status = true;
          setTrees(trees);
          return true;
        }).catch((error) => {
          console.error(error);
          if (typeof error === 'string') {
            row.status = error;
          } else {
            row.status = error.error.message.value;
          }
          setTrees(trees);
          setErrorStep(1);
        });
        if (!sucess) {
          error = true;
        }
      }
    }
    setIsLoading(false);
    if (!error) {
      handleNext();
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" scroll="paper" open={open} onClose={handleClose}>
      <DialogTitle>{steps[activeStep]}</DialogTitle>
      <DialogContent dividers>
        <Steps activeStep={activeStep}>
          <StepImport onChange={handleChangeFile} value={file} />
          <StepMap onChange={handleChangeTrees} value={Object.values(trees)} />
          <StepSave value={Object.values(trees)} />
          <StepFinish value={Object.values(trees)} />
        </Steps>
        <Backdrop open={fetchingTrees} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
              <Step>
                <StepLabel>Importar</StepLabel>
              </Step>
              <Step>
                <StepLabel error={errorStep === 1}>Mapear</StepLabel>
              </Step>
              <Step>
                <StepLabel>Salvar</StepLabel>
              </Step>
              <Step>
                <StepLabel>Finalizar</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              color="error" disabled={isLoading || activeStep + 1 === steps.length}
              onClick={handleCancel} variant="contained"
            >Cancelar</Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading || activeStep === 0 || activeStep + 1 === steps.length}
              onClick={handleBack}
            >Anterior</Button>
          </Grid>
          <Grid item>
            <Steps activeStep={activeStep}>
              <Button disabled={data.length === 0} onClick={handleNext}>Próximo</Button>
              <Button onClick={handleNext}>Próximo</Button>
              <>
                <Button color="secondary" disabled={isLoading} onClick={handleNext} variant="outlined">Pular</Button>
                <Button disabled={isLoading || errorStep !== null} onClick={handleSave} variant="contained">Salvar</Button>
              </>
              <>
                <Button color="secondary" onClick={handleReset} variant="outlined">Nova</Button>
                <Button onClick={handleSubmit} variant="contained">Finalizar</Button>
              </>
            </Steps>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ImportWizard.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
};

function Steps({ activeStep, children }) {
  return children[activeStep];
}

Steps.defaultProps = {
  activeStep: 0,
  children: [],
};

export default ImportWizard;
