
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Backdrop,
  Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, Grid, Icon,
  TextField,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import ItemsTable from './table';
import { fetchItems, fetchManufacturers, materialTypes, treeTypes, manufacturers } from './fetchData';
import dynTheme from 'assets/dynTheme';
import { exportTemplate } from './exportData';
import ImportWizard from './wizard';

let debounceTimer;
function debounce(func, delay) {
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

function ItemsView() {

  const [refresh, setRefresh] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const [showWizard, setShowWizard] = useState(false);

  const [items, setItems] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(false);
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState('');
  const [materialType, setMaterialType] = useState(materialTypes[0]);
  const [treeType, setTreeType] = useState(treeTypes[0]);
  const [foreign, setForeign] = useState('');
  const [frozen, setFrozen] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingItems(true);
    fetchItems(
      sessionID,
      code,
      materialType?.id,
      treeType?.id,
      foreign,
      frozen,
    ).then((data) => {
      if (data != null) {
        setItems(data);
      }
    }).finally(() => {
      setFetchingItems(false);
    });
  }, [materialType, treeType, frozen, refresh]);

  useEffect(() => {
    setLoading(true);
    const sessionID = Cookies.get('sessionID');
    fetchManufacturers(sessionID)
      .finally(() => {
        console.log(manufacturers)
        setLoading(false);
      });
  }, []);

  const delayRefresh = debounce(() => {
    setRefresh(!refresh);
  }, 1500);

  const handleChangeCode = (event) => {
    setCode(event.target.value);
    delayRefresh();
  }

  const handleChangeMaterialType = (event, newValue) => {
    setMaterialType(newValue);
  }

  const handleChangeTreeType = (event, newValue) => {
    setTreeType(newValue);
  }

  const handleChangeForeign = (event) => {
    setForeign(event.target.value);
    delayRefresh();
  }

  const handleChangeFrozen = (event) => {
    setFrozen(event.target.checked);
  }

  const handleDetail = (id) => {
    setDetailId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setDetailId(null);
  }

  const handleSubmitForm = () => {
    setRefresh(!refresh);
  }

  const handleOpenWizard = (e) => {
    e.preventDefault();
    setShowWizard(true);
  }

  const handleCloseWizard = () => {
    setShowWizard(false);
  }

  const handleSubmitWizard = () => {
    setRefresh(!refresh);
  }

  const handleExportTemplate = async (e) => {
    e.preventDefault();
    setLoading(true);
    await exportTemplate();
    setLoading(false);
  }

  const handleExportRows = async (e) => {
    e.preventDefault();
    setLoading(true);
    await exportTemplate(items);
    setLoading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Items
              </MDTypography>
            </Box>
            <Grid container spacing={2} style={{ width: 'auto' }}>
              <Grid item xs={12} md={4}>
                <MDButton disabled fullWidth variant="gradient" color="warning" >
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>add</Icon>
                  Novo
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton fullWidth onClick={handleOpenWizard} variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>upload</Icon>
                  Importação
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton fullWidth onClick={handleExportTemplate} variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>tablehart</Icon>
                  Template
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      onChange={handleChangeCode}
                      label="Código"
                      value={code}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      fullWidth
                      onChange={handleChangeMaterialType}
                      options={materialTypes}
                      renderInput={(params) => <TextField {...params} label="Material" />}
                      value={materialType}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      fullWidth
                      onChange={handleChangeTreeType}
                      options={treeTypes}
                      renderInput={(params) => <TextField {...params} label="Estrutura" />}
                      value={treeType}
                    />
                  </Grid>
                  <Grid item xs={0} md={3} />
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      onChange={handleChangeForeign}
                      label="Descrição Auxiliar"
                      value={foreign}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControlLabel
                      control={<Checkbox checked={frozen} onChange={handleChangeFrozen} />}
                      label="Mostrar Inativos?"
                    />
                  </Grid>
                  <Grid item xs={0} md={4} />
                  <Grid item xs={12} md={2}>
                    <Button
                      disabled={items.length === 0}
                      fullWidth size="large" variant="outlined"
                      onClick={handleExportRows}
                    >
                      <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>download</Icon>
                      Exportar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <ItemsTable
                  data={items}
                  isLoading={fetchingItems}
                />
              </Grid>
            </Grid>
            <ImportWizard
              open={showWizard}
              onClose={handleCloseWizard}
              onSubmit={handleSubmitWizard}
            />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress />
            </Backdrop>
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default ItemsView;
