import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete, Box, Button, Card,
  Grid, Icon, TextField, createTheme,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useStatus } from 'hooks/useStatus';

import EmployeeModal from './form';
import EmployeeTable from './table';
import { roles, statuses, fetchEmployees } from './fetchData';
import { exportEmployeesToXLSX } from './exportData';
import dynTheme from 'assets/dynTheme';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

function EmployeeView() {

  const { toggleStatus } = useStatus();

  const [refresh, setRefresh] = useState(false);

  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const fileRef = useRef();

  const isManager = Cookies.get('logemployeerole') === 'Manager';

  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);

  const [costCenter, setCostCenter] = useState('');
  const [active, setActive] = useState(statuses[0]);
  const [portalRole, setPortalRole] = useState(null);

  const [fetchingEmployees, setFetchingEmployees] = useState(false);

  useEffect(() => {
    if (isManager) {
      setEmployee({
        EmployeeID: Cookies.get('logemployeeID'),
        FirstName: Cookies.get('logemployeefirstname'),
        LastName: Cookies.get('logemployeelastname'),
        label: `${Cookies.get('logemployeeID')} - ${Cookies.get('logemployeefirstname')} ${Cookies.get('logemployeelastname')}`,
      });
    }
  }, []);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    if (isManager) {
      setFetchingEmployees(true);
      fetchEmployees(
        sessionID,
        costCenter,
        active,
        portalRole,
      ).then((data) => {
        if (data != null) {
          setEmployees(data);
        }
      }).finally(() => {
        if (!debounceTimer) {
          setFetchingEmployees(false);
        }
        setRefresh(false);
      });
    }
  }, [employee, active, portalRole, refresh]);

  const delayRefresh = debounce(() => {
    setRefresh(true);
  }, 1500);

  const handleChangeCostCenter = (e) => {
    setCostCenter(e.target.value);
    delayRefresh();
  }

  const handleChangeActive = (e, v) => {
    e.preventDefault();
    setActive(v);
  }

  const handleChangePortalRole = (e, v) => {
    e.preventDefault();
    setPortalRole(v);
  }

  const handleOpenAddForm = (e) => {
    e.preventDefault();
    if (isManager) {
      setShowForm(true);
    } else {
      toggleStatus('Atenção', 'Funcionalidade limitada por política organizacional', 'warning');
    }
  }

  const handleEditForm = (id) => {
    if (isManager) {
      setEditId(id);
      setShowForm(true);
    } else {
      toggleStatus('Atenção', 'Funcionalidade limitada por política organizacional', 'warning');
    }
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setEditId(null);
  }

  const handleSubmitForm = () => {
    handleCloseForm();
    setRefresh(true);
  }

  const handleExportRows = (e) => {
    e.preventDefault();
    exportEmployeesToXLSX(employees);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Cadastro de Colaboradores
              </MDTypography>
            </Box>
            <Grid container spacing={2} style={{ width: 'auto' }}>
              <Grid item xs={12} md={4}>
                <MDButton fullWidth variant="gradient" color="warning" onClick={handleOpenAddForm}>
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>add</Icon>
                  Novo
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton
                  disabled
                  fullWidth variant="gradient"
                  color="warning" component={undefined}
                >
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>upload</Icon>
                  Importar
                  <input
                    type="file"
                    accept=".xlsx"
                    hidden
                    multiple={false}
                    ref={fileRef}
                  />
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton disabled fullWidth variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>tablechart</Icon>
                  Template
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Centro de Custo"
                      onChange={handleChangeCostCenter}
                      value={costCenter}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      fullWidth
                      onChange={handleChangeActive}
                      options={statuses}
                      renderInput={(params) => <TextField {...params} label="Status" />}
                      value={active}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      fullWidth
                      onChange={handleChangePortalRole}
                      options={roles}
                      renderInput={(params) => <TextField {...params} label="Permissão" />}
                      value={portalRole}
                    />
                  </Grid>
                  <Grid item xs={0} md={2} />
                  <Grid item xs={12} md={2}>
                    <Button
                      disabled={employees.length === 0}
                      fullWidth color="warning" size="large" variant="outlined"
                      onClick={handleExportRows}
                    >
                      <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>download</Icon>
                      Exportar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <EmployeeTable
                  data={employees}
                  isLoading={fetchingEmployees}
                  onEdit={handleEditForm}
                />
              </Grid>
            </Grid>
            <EmployeeModal
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
              id={editId}
            />
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default EmployeeView;
