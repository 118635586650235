import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import dataGridText from "assets/dataGridText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const columns = [
  { field: 'index', headerName: 'Linha', width: 50, editable: false, },
  { field: 'DocNum', headerName: 'ID', width: 50, editable: false, },
  { field: 'FromWarehouse', headerName: 'De', width: 100, editable: false, },
  { field: 'ToWarehouse', headerName: 'Para', width: 100, editable: false, },
  { field: 'DocDate', headerName: 'Abertura', width: 150, type: 'date', editable: false, },
  { field: 'DueDate', headerName: 'Vencimento', width: 150, type: 'date', editable: true, },
  { field: 'Comments', headerName: 'Comentários', width: 300, editable: false, },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    width: 300,
    renderCell: (params) => {
      if (params.row.status === true) {
        return <Alert severity="success" variant="outlined">Salvo</Alert>;
      }
      else if (params.row.status) {
        return <Alert severity="error" variant="filled">Erro: {params.row.status}</Alert>;
      }
      return null;
    },
  },
];

export default function StepMap({ onChange, value }) {

  const [data, setData] = useState([]);

  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    setData(value);
  }, [value]);

  useEffect(() => {
    if (dueDate) {
      const date = dueDate.toDate()
      const newData = data.map((row) => {
        if (row.saved) {
          return row;
        }
        return {
          ...row,
          DueDate: date,
        }
      });
      onChange(newData);
    }
  }, [dueDate]);

  const handleChangeDueDate = (date) => {
    setDueDate(date);
  }

  const handleChangeData = (newData, oldData) => {
    const index = data.findIndex((row) => row.id === oldData.id);
    if (index >= 0) {
      const updatedData = [...data];
      updatedData[index] = newData;
      onChange(updatedData);
    }
    return oldData;
  }

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
          <Grid item xs={12} md={4} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
              <DatePicker
                id="data-final"
                label="Data Vencimento"
                onChange={handleChangeDueDate}
                value={dueDate}
                disablePast
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          processRowUpdate={handleChangeData}
          onProcessRowUpdateError={(error) => console.error(error)}
          columns={columns}
          isCellEditable={(params) => !params.row.saved}
          isRowSelectable={() => false}
          editMode="row"
          style={{ height: '50vh' }}
          localeText={dataGridText}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}