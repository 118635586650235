import dayjs from "dayjs";

export const statuses = [
  { simple: 'R', complex: 'boposReleased', label: 'Liberada' },
  { simple: 'L', complex: 'boposClosed', label: 'Fechada' },
  { simple: 'P', complex: 'boposPlanned', label: 'Planejada' },
  { simple: 'C', complex: 'boposCanceled', label: 'Cancelada' },
];

export const retrabalhos = ['Engenharia', 'Fábrica', 'Cliente'];

export async function fetchProjects(sessionID) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/QueryService_PostQuery");
  const filters = [
    "ProductionOrders/AbsoluteEntry eq ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry",
    "ProductionOrders/ProductionOrderStatus eq 'R'",
    "ProductionOrders/ProductionOrderLines/ResourceAllocation eq 'S'",
    "Projects/Code eq ProductionOrders/Project",
  ]
  const group = [
    "Projects/Code",
    "Projects/Name",
    "ProductionOrders/AbsoluteEntry",
    "ProductionOrders/Project"
  ]
  const body = {
    "QueryPath": "$crossjoin(Projects, ProductionOrders, ProductionOrders/ProductionOrderLines)",
    "QueryOption": `$apply=filter(${filters.join(' and ')})/groupby((${group.join(' , ')}))`
  };

  let data = [];
  let repeat = true;
  let read = 0;

  while (repeat) {
    repeat = false;
    body.QueryOption = body.QueryOption.concat(`&$skip=${read}`);
    await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            const line = item['Projects'];
            line['id'] = line['Code'];
            line['label'] = line['Code'];
            data.push(line);
          });
          read += json.value.length;
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }

  return data;
}

export async function fetchOpenProductionOrders(sessionID, projectID = null, status = []) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(ProductionOrders, ProductionOrders/ProductionOrderLines)");
  let data = [];
  let data_keys = {};
  let repeat = true;
  let read = 0;

  const expand = [
    'ProductionOrders($select=AbsoluteEntry, ProductionOrderStatus, ProductDescription, ProductionOrderType, CreationDate, StartDate, ItemNo, Project, DocumentNumber)',
    'ProductionOrders/ProductionOrderLines($select=DocumentAbsoluteEntry, LineNumber, ResourceAllocation, ItemNo, ItemName, ItemType, PlannedQuantity, IssuedQuantity)'
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filter = [
    "ProductionOrders/AbsoluteEntry eq ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry",
    "ProductionOrders/ProductionOrderLines/ResourceAllocation eq 'S'",
  ];
  if (projectID) {
    filter.push(`ProductionOrders/Project eq '${projectID}'`);
  }
  if (status.length > 0) {
    const f = [];
    status.forEach((item) => {
      f.push(`ProductionOrderStatus eq '${item}'`);
    });
    filter.push(`(${f.join(' or ')})`);
  }
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'ProductionOrders/CreationDate desc',
    'ProductionOrders/ProductionOrderLines/LineNumber asc'
  ];
  apiUrl.searchParams.append('$orderby', order.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', read);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            if (item['ProductionOrders']['AbsoluteEntry'] in data_keys) {
              const line = item['ProductionOrders/ProductionOrderLines'];
              line['id'] = line['LineNumber'];
              line['label'] = String(line['ItemNo']).concat(' - ').concat(line['ItemName']);
              data[data_keys[item['ProductionOrders']['AbsoluteEntry']]]['ProductionOrderLines']
                .push(line);
              return;
            }
            const value = item['ProductionOrders'];
            value['id'] = item['ProductionOrders']['AbsoluteEntry'];
            value['label'] = String(item['ProductionOrders']['ItemNo'])
              .concat(' - ').concat(item['ProductionOrders']['ProductDescription'])
              .concat(' - ').concat(item['ProductionOrders']['DocumentNumber']);
            const line = item['ProductionOrders/ProductionOrderLines'];
            line['id'] = line['LineNumber'];
            line['label'] = String(line['ItemNo']).concat(' - ').concat(line['ItemName']);
            value['ProductionOrderLines'] = [line];
            data_keys[value['id']] = data.length;
            data.push(value);
          });
          read += json.value.length;
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchInvGenExits(sessionID, startDate, endDate, employeeID=null, projectID=null, status=[], productionOrderID=null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(InventoryGenExits, InventoryGenExits/DocumentLines, ProductionOrders, Projects, ProductionOrders/ProductionOrderLines, EmployeesInfo)');
  const data = [];
  let repeat = true;

  const expand = [
    'InventoryGenExits($select=DocEntry, DocNum, DocDate)',
    'InventoryGenExits/DocumentLines($select=DocEntry, LineNum, BaseEntry, BaseLine, Quantity, U_EASY_Colab, U_EASY_HoraIni, U_U_EASY_HoraFim, U_TextoLivre3, U_TextoLivre4, U_TextoLivre5)',
    'ProductionOrders($select=AbsoluteEntry, DocumentNumber, ProductDescription, ItemNo, Project)',
    'Projects($select=Code, Name)',
    'ProductionOrders/ProductionOrderLines($select=DocumentAbsoluteEntry, LineNumber, ItemNo, ItemName)',
    'EmployeesInfo($select=EmployeeID, FirstName, LastName)'
  ];
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filter = [
    'InventoryGenExits/DocEntry eq InventoryGenExits/DocumentLines/DocEntry',
    'InventoryGenExits/DocumentLines/BaseEntry eq ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry',
    'InventoryGenExits/DocumentLines/BaseLine eq ProductionOrders/ProductionOrderLines/LineNumber',
    'ProductionOrders/ProductionOrderLines/DocumentAbsoluteEntry eq ProductionOrders/AbsoluteEntry',
    'ProductionOrders/Project eq Projects/Code',
    'InventoryGenExits/DocumentLines/U_EASY_Colab eq EmployeesInfo/EmployeeID',
    "ProductionOrders/ProductionOrderLines/ResourceAllocation eq 'S'",
    "InventoryGenExits/DocDate ge '".concat(startDate).concat("'"),
    "InventoryGenExits/DocDate le '".concat(endDate).concat("'"),
  ];
  if (employeeID) {
    filter.push(`InventoryGenExits/DocumentLines/U_EASY_Colab eq ${employeeID}`);
  }
  if (projectID) {
    filter.push(`ProductionOrders/Project eq '${projectID}'`);
  }
  if (status.length > 0) {
    const f = [];
    status.forEach((item) => {
      f.push(`ProductionOrders/ProductionOrderStatus eq '${item}'`);
    });
    filter.push(`(${f.join(' or ')})`);
  }
  if (productionOrderID) {
    filter.push(`InventoryGenExits/DocumentLines/BaseEntry eq ${productionOrderID}`);
  }
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'InventoryGenExits/DocDate desc',
    'InventoryGenExits/DocEntry desc',
    'InventoryGenExits/DocumentLines/LineNum asc'
  ];
  apiUrl.searchParams.append('$orderby', order.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            item['id'] = item['InventoryGenExits']['DocEntry'];
            const op = item['ProductionOrders'];
            item['op'] = String(op['ItemNo']).concat(' - ').concat(op['ProductDescription']).concat(' - ').concat(op['DocumentNumber']);
            const pr = item['Projects'];
            item['pr'] = pr['Name']; 
            const serv = item['ProductionOrders/ProductionOrderLines'];
            item['srv'] = String(serv['ItemNo']).concat(' - ').concat(serv['ItemName']);
            const ap = item['InventoryGenExits/DocumentLines'];
            let time = ap['U_EASY_HoraIni'].toString().padStart(4, '0');
            time = `${time.slice(0, 2)}:${time.slice(2)}`;
            item['datetime'] = String(dayjs(item['InventoryGenExits']['DocDate']).format('DD/MM/YYYY')).concat(' - ').concat(time);
            item['date'] = dayjs(item['InventoryGenExits']['DocDate']).format('DD/MM/YYYY');
            item['qtd'] = String((ap['Quantity'] / 60 || 0).toFixed(2)).replace('.', ',');
            const emp = item['EmployeesInfo'];
            item['emp'] = `${emp['EmployeeID']} - ${emp['FirstName']} ${emp['LastName']}`;
            item['startTime'] = time;
            time = ap['U_U_EASY_HoraFim'].toString().padStart(4, '0');
            item['endTime'] = `${time.slice(0, 2)}:${time.slice(2)}`;
            item['holi'] = ap['U_TextoLivre3'] === 'tYES' ? true : false;
            item['rwk'] = ap['U_TextoLivre4'];
            item['obs'] = ap['U_TextoLivre5'];
            data.push(item)
          });
          repeat = true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}


export async function createInventoryExit(
  sessionID,
  { EmployeeID, ProductionOrder, ProductionOrderLine, Date, StartTime, EndTime, Holiday, Rework, Observations }
) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/InventoryGenExits');

  const body = {
    BPL_IDAssignedToInvoice: 3,
    DocDate: Date,
    DocumentLines: [
      {
        Quantity: (EndTime - StartTime) / 60000,
        ItemType: ProductionOrderLine['ItemType'],
        WarehouseCode: ProductionOrderLine['Warehouse'],
        BaseType: ProductionOrder['ProductionOrderType'],
        BaseEntry: ProductionOrder['AbsoluteEntry'],
        BaseLine: ProductionOrderLine['LineNumber'],
        U_EASY_Colab: EmployeeID,
        U_EASY_HoraIni: StartTime.format('HH:mm'),
        U_U_EASY_HoraFim: EndTime.format('HH:mm'),
        U_TextoLivre3: Holiday,
        U_TextoLivre4: Rework,
        U_TextoLivre5: Observations,
      },
    ],
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${sessionID}`
    },
    body: JSON.stringify(body)
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
}

