import { DataGrid } from '@mui/x-data-grid';
import dataGridText from 'assets/dataGridText';

const columns = [
  { field: 'id', headerName: 'Nº', width: 50, editable: false, },
  { field: 'ItemCode', headerName: 'Item', width: 150, editable: false, },
  { field: 'Warehouse', headerName: 'Depósito', width: 100, editable: false, },
  { field: 'Quantity', headerName: 'Quantidade', width: 100, editable: false, },
  { field: 'ItemName', headerName: 'Descrição', width: 400, editable: false, },
];

function ProductsTable({ data }) {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      style={{ height: '50vh' }}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSorting
      localeText={dataGridText}
    />
  );
}

export default ProductsTable;