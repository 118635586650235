import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, FormControlLabel, Grid, IconButton,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, Send } from '@mui/icons-material';
import Cookies from 'js-cookie';

import { useStatus } from '../../hooks/useStatus';
import { fetchOrder, fetchInventoryExits, createInventoryExit } from './fetchData';
import ExitsTable from './subtable';
import ExitSubForm from './subform';
import dayjs from 'dayjs';

const InventroyExitDetailModal = ({ open, onClose, onSubmit, id }) => {

  const [docNum, setDocNum] = useState('');
  const [project, setProject] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [item, setItem] = useState('');
  const [showExiteds, setShowExiteds] = useState(false);
  const [barcode, setBarcode] = useState('');

  const [lines, setLines] = useState([]);
  const [exits, setExits] = useState([]);
  const [pickings, setPickings] = useState([]);
  const [codes, setCodes] = useState({});

  const { status, toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingOrder, setFetchingOrder] = useState(false);
  const [fetchingExits, setFetchingExits] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [picking, setPicking] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setRefresh(false);
    if (id && !fetchingOrder && !fetchingExits) {
      const sessionID = Cookies.get('sessionID');
      setFetchingOrder(true);
      fetchOrder(sessionID, id).then((data) => {
        if (data != null) {
          setDocNum(data['DocumentNumber']);
          setProject(data['Project']);
          setDueDate(data['DueDate']);
          if (data['Item']) {
            setItem(data['Item']);
          }
          setLines(data['ProductionOrderLines'].map((line) => ({
            id: line['LineNumber'],
            ...line,
            'total': `${line['IssuedQuantity']}/${line['BaseQuantity']}`,
          })).sort((a, b) => {
            if (a['InStock'] <= 0 && b['InStock'] > 0) {
              return 1;
            } else if (a['InStock'] > 0 && b['InStock'] <= 0) {
              return -1;
            }
            return 0;
          }));
        }
      }).finally(() => setFetchingOrder(false));
      setFetchingExits(true);
      fetchInventoryExits(sessionID, id).then((data) => {
        if (data != null) {
          setExits(data);
        }
      }).finally(() => setFetchingExits(false));
    }
  }, [id, refresh]);

  useEffect(() => {
    const hashes = {};
    lines.forEach((line, index) => {
      hashes[line['ItemNo']] = index;
    });
    setCodes(hashes);
  }, [lines]);

  useEffect(() => {
    let items = [];
    lines.forEach((line) => {
      const item = pickings.find((p) => p['BaseLine'] === line['LineNumber']);
      const transfered = line['IssuedQuantity'];
      if (item) {
        line['total'] = `${transfered + item['Quantity']}/${line['BaseQuantity']}`;
      } else {
        line['total'] = `${transfered}/${line['BaseQuantity']}`;
      }
      items.push(line);
    });
    setLines(items);
  }, [pickings])

  useEffect(() => {
    if (barcode) {
      let bar = barcode.replace(/=/g, '-');
      bar = bar.replace(/ç/g, ':');
      const index = codes[bar];
      if (index !== undefined) {
        handleEditForm(index);
      } else {
        toggleStatus('Código de barras não encontrado!', `Verifique se o código ${bar} está cadastrado e tente novamente.`, 'warning');
      }
      setBarcode('');
    }
  }, [barcode]);

  const handleRemovePicking = (index) => {
    const line = pickings[index];
    setPickings(pickings.filter((p) => p['BaseLine'] !== line['BaseLine']));
  }

  const handleToggleExiteds = (e) => {
    setShowExiteds(!showExiteds);
  }

  const handleReset = () => {
    setDocNum('');
    setProject('');
    setDueDate('');
    setItem('');
    setLines([]);
    setExits([]);
    setPickings([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleEditForm = (index) => {
    setEditIndex(index);
    const line = lines[index]['LineNumber'];
    const picking = pickings.find((p) => p['BaseLine'] === line);
    setPicking(picking);
    setShowEdit(true);
  }

  const handleCloseForm = () => {
    setEditIndex(null);
    setPicking(null);
    setShowEdit(false);
  }

  const handleFormSubmit = (line) => {
    let picks = [...pickings];
    if (picking) {
      picks = pickings.filter((p) => p['BaseLine'] !== picking['BaseLine']);
    }
    if (line['BatchNumbers'].length > 0) {
      setPickings([...picks, line]);
    }
    setShowEdit(false);
    setEditIndex(null);
    setPicking(null);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving('Salvando');
    const sessionID = Cookies.get('sessionID');
    const data = {
      "BPL_IDAssignedToInvoice": 3,
      "DocDate": dayjs().format('YYYY-MM-DD'),
      "DocumentLines": pickings.map((p) => ({ ...p, "ItemCode": undefined })),
    };
    createInventoryExit(sessionID, data).then((response) => {
      console.log(response);
      handleReset();
      onSubmit();
      onClose();
      toggleStatus('Consumo salvo com sucesso!', `Criado Saída de Inventário Nº ${response['DocNum']}`, 'success');
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar consumo!', { error: error.error, data }, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const handleChangeBarcode = useCallback((value) => {
    setBarcode(value);
  }, []);

  const highlightPickingCallback = (line) => {
    for (let i = 0; i < pickings.length; i++) {
      const p = pickings[i];
      if (p['BaseLine'] === line['id']) {
        if (line['BaseQuantity'] - line['IssuedQuantity'] >= p['Quantity']) {
          return 'linear-gradient(#0F02, #0F02)';
        }
        return 'linear-gradient(#FF02, #FF02)'
      }
    }
    return 'inherit';
  };

  const highlightExitCallback = (picking) => {
    if (picking['id'] === undefined) {
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (picking['BaseLine'] === line['id']) {
          if (line['BaseQuantity'] - line['IssuedQuantity'] >= picking['Quantity']) {
            return 'linear-gradient(#0F02, #0F02)';
          }
          return 'linear-gradient(#FF02, #FF02)';
        }
      }
    }
    return 'inherit';
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Consumo de Material da Ordem de Produção
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="OP" value={docNum} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Projeto" value={project} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Data Fim" value={dueDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField fullWidth label="Item" value={item} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={<Checkbox checked={showExiteds} color="warning" onChange={handleToggleExiteds} />}
              label="Mostrar Consumidos"
            />
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <BarCodeInput canFocus={!showEdit && !status.isOpen} onChange={handleChangeBarcode} />
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="div" mt={2}>Items</Typography>
            <ExitsTable
              lines={lines}
              icon={<Send />}
              onClick={handleEditForm}
              showMU
              filterCallback={(line) => showExiteds ? true : line['IssuedQuantity'] === 0}
              highlightCallback={highlightPickingCallback}
              enabledCallback={(line) => line['InStock'] > 0}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="div" mt={2}>Consumos</Typography>
            <ExitsTable
              lines={[...pickings, ...exits]}
              icon={<Cancel />}
              onClick={handleRemovePicking}
              enabledCallback={(line) => line['id'] === undefined}
              filterCallback={(line) => showExiteds ? true : line['id'] === undefined}
              highlightCallback={highlightExitCallback}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" onClick={() => setPickings([])} variant="text">Limpar</Button>
        <Button color="warning" disabled={pickings.length === 0} onClick={handleSubmit} variant="contained">Salvar</Button>
      </DialogActions>
      <ExitSubForm
        open={showEdit}
        onClose={handleCloseForm}
        onSubmit={handleFormSubmit}
        line={lines[editIndex]}
        item={item}
        picking={picking}
      />
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingOrder || fetchingExits} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

InventroyExitDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

function BarCodeInput({ canFocus, onChange }) {

  const handleEnter = useCallback((e) => {
    if (e.key === 'Enter') {
      const val = "" + e.currentTarget.value;
      onChange(val);
      e.preventDefault();
      e.currentTarget.value = "";
    }
  }, [onChange]);

  const handleFocus = useCallback((e) => {
    if (canFocus) {
      e.currentTarget.focus({ preventScroll: true });
    }
  }, [canFocus]);

  return (
    <TextField
      autoFocus
      fullWidth
      defaultValue=""
      inputProps={{ inputMode: 'none' }}
      label="Código de Barras"
      onBlur={handleFocus}
      onKeyUp={handleEnter}
      variant="outlined"
    />
  );
}

export default InventroyExitDetailModal;
