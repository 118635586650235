import dayjs from "dayjs";

export async function fetchPurchaseOrders(sessionID, startDate = null, endDate = null, docNum = null, project = null, cardCode = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders');
  let data = [];
  let repeat = true;

  const select = [
    'DocEntry',
    'DocNum',
    'DocDate',
    'DocDueDate',
    'CardCode',
    'CardName',
    'Project',
    'UpdateDate',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [];

  if (docNum) {
    filters.push(`DocNum eq ${docNum}`);
  } else {
    if (project) {
      filters.push(`contains(Project, '${project}')`);
    }
    if (cardCode) {
      filters.push(`contains(CardCode, '${cardCode}')`);
    }
    if (startDate) {
      filters.push(`DocDate ge ${startDate}`);
    }
    if (endDate) {
      filters.push(`DocDate le ${endDate}`);
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  apiUrl.searchParams.append('$orderby', 'UpdateDate desc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['DocEntry'];
            item['Card'] = `${item['CardCode']} - ${item['CardName']}`;
            item['createDate'] = dayjs(item['DocDate']);
            item['dueDate'] = dayjs(item['DocDueDate']);
            item['updateDate'] = dayjs(item['UpdateDate']);
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchPurchaseOrder(sessionID, id=null, docNum=null) {
  let url = 'https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders';
  if (id) {
    url += `(${id})`;
  }
  const apiUrl = new URL(url);

  if (docNum && !id) {
    apiUrl.searchParams.append('$filter', `DocNum eq ${docNum}`);
  }

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  if (id) {
    return data;
  }
  return data.value[0];
}

export async function patchPurchaseOrder(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/PurchaseOrders(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return;
}

export async function updatePurchaseOrderShipDate(sessionID, data) {
  const purchase = await fetchPurchaseOrder(sessionID, null, data.DocNum);

  const lines = data.DocumentLines.map((line) => {
    const product = purchase.DocumentLines.find((item) => item.LineNum == line.LineNum);
    if (product) {
      const hist = product.U_EASY_INfoAdic;
      const histDate = `${dayjs().format('DD/MM/YYYY')} - ${dayjs(product.ShipDate).format('DD/MM/YYYY')}`;
      if (hist && hist.length > 0) {
        line.U_EASY_INfoAdic = hist.concat(';', histDate);
        if (line.U_EASY_INfoAdic.length > 191) {
          line.U_EASY_INfoAdic = line.U_EASY_INfoAdic.slice(24);
        }
      } else {
        line.U_EASY_INfoAdic = line.histDate;
      }
    }
    return line;
  });

  return await patchPurchaseOrder(sessionID, purchase.DocEntry, { DocumentLines: lines });
}

export async function fetchSuppliers(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/BusinessPartners');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'CardCode,CardName');
  apiUrl.searchParams.append('$filter', "CardType eq 'cSupplier'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['CardCode'];
            item['label'] = `${item['CardCode']} - ${item['CardName']}`;
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}
