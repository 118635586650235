import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";

export default function StepFinish({ value }) {

  const [sucess, setSucess] = useState(0);
  const [error, setError] = useState(0);

  useEffect(() => {
    let s = 0;
    let e = 0;
    value.forEach((row) => {
      if (row.status === true) {
        s++;
      }
      else if (row.status) {
        e++;
      }
    });
    setSucess(s);
    setError(e);
  }, [value]);

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Info sucess={sucess} error={error} />
    </Grid>
  )
}

function Info({ sucess, error }) {
  let content = [];
  if (sucess > 0) {
    content.push(
      <Grid item key="s">
        <Alert severity="success" variant="outlined">Registros salvos: {sucess}</Alert>
      </Grid>
    );
  }
  if (error > 0) {
    content.push(
      <Grid item key="e">
        <Alert severity="error" variant="filled">Registros com erros: {error}</Alert>
      </Grid>
    );
  }
  if (content.length === 0) {
    content.push(
      <Grid item key="i">
        <Alert severity="info" variant="outlined">Nenhum registro foi salvo</Alert>
      </Grid>
    );
  }
  return content;
}