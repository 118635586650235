import Cookies from "js-cookie";
import { createTheme } from "@mui/material";
import { teal } from "@mui/material/colors";
import { ptBR } from '@mui/material/locale';

const darkMode = Cookies.get('darkMode') === 'true';

const dark = {
  background: {
    default: '#30373a',
    paper: '#30373a',
  },
}

const light = {
  background: {
    default: '#f0f2f5',
  },
}

const dynTheme = createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#ec9418',
      contrastText: '#fff',
    },
    secondary: {
      main: teal[500],
    },
    ...(darkMode ? dark : light),
  },
}, ptBR);

export default dynTheme;
