import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Step, StepLabel, Stepper,
} from '@mui/material';
import Cookies from 'js-cookie';

import { updatePurchaseOrderShipDate } from '../fetchData'
import StepMap from './stepMap';
import StepImport from './stepImport';
import StepSave from './stepSave';
import StepFinish from './stepFinish';
import dayjs from 'dayjs';

function ImportWizard({ open, onClose, onSubmit }) {

  const steps = [
    'Importe o arquivo',
    'Mapeie os campos',
    'Salve as linhas',
    'Finalizando a importação',
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [errorStep, setErrorStep] = useState(null);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [purchases, setPurchases] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeData = (value) => {
    const rows = {};
    for (let index = 0; index < value.length; index++) {
      const row = value[index];
      const line = {
        LineNum: row.lineNum,
        ShipDate: row.shipDate && dayjs(row.shipDate).format('YYYY-MM-DD'),
      };
      if (rows[row.docNum]) {
        rows[row.docNum].lines += 1;
        rows[row.docNum].DocumentLines.push(line);
      } else {
        rows[row.docNum] = {
          id: row.docNum,
          DocumentLines: [line],
          lines: 1,
          status: row.status,
        };
      }
      row.line = rows[row.docNum].lines;
    }
    setPurchases(rows);
    setData(value);

    if (errorStep === activeStep) {
      setErrorStep(null);
    }
  }

  const handleChangeFile = (value) => {
    setFile(value);
    if (value.data) {
      handleChangeData(value.data);
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setData([]);
    setErrorStep(null);
    setFile(null);
    setPurchases({});
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    handleClose(e);
  }

  const handleClose = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data);
    handleReset();
    handleClose(e);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let error = false;
    const sessionID = Cookies.get('sessionID');
    for (const code in purchases) {
      const row = purchases[code];
      row.status = false;
      setPurchases(purchases);

      let sucess = await updatePurchaseOrderShipDate(
        sessionID,
        {
          DocNum: row.id,
          DocumentLines: row.DocumentLines,
        }
      ).then(() => {
        row.status = true;
        setPurchases(purchases);
        console.log(purchases)
        return true;
      }).catch((error) => {
        if (typeof error === 'string') {
          row.status = error;
        } else {
          row.status = error.error.message.value;
        }
        setPurchases(purchases);
        console.error(error);
        setErrorStep(1);
      });
      if (!sucess) {
        error = true;
      }
    }
    setIsLoading(false);
    if (!error) {
      handleNext();
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" scroll="paper" open={open} onClose={handleClose}>
      <DialogTitle>{steps[activeStep]}</DialogTitle>
      <DialogContent dividers>
        <Steps activeStep={activeStep}>
          <StepImport onChange={handleChangeFile} value={file} />
          <StepMap onChange={handleChangeData} value={Object.values(purchases)} />
          <StepSave value={Object.values(purchases)} />
          <StepFinish value={Object.values(purchases)} />
        </Steps>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
              <Step>
                <StepLabel>Importar</StepLabel>
              </Step>
              <Step>
                <StepLabel error={errorStep === 1}>Mapear</StepLabel>
              </Step>
              <Step>
                <StepLabel>Salvar</StepLabel>
              </Step>
              <Step>
                <StepLabel>Finalizar</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              color="error" disabled={isLoading || activeStep + 1 === steps.length}
              onClick={handleCancel} variant="contained"
            >Cancelar</Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading || activeStep === 0 || activeStep + 1 === steps.length}
              onClick={handleBack}
            >Anterior</Button>
          </Grid>
          <Grid item>
            <Steps activeStep={activeStep}>
              <Button disabled={data.length === 0} onClick={handleNext}>Próximo</Button>
              <Button onClick={handleNext}>Próximo</Button>
              <>
                <Button color="secondary" disabled={isLoading} onClick={handleNext} variant="outlined">Pular</Button>
                <Button disabled={isLoading || errorStep !== null} onClick={handleSave} variant="contained">Salvar</Button>
              </>
              <>
                <Button color="secondary" onClick={handleReset} variant="outlined">Nova</Button>
                <Button onClick={handleSubmit} variant="contained">Finalizar</Button>
              </>
            </Steps>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ImportWizard.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
};

function Steps({ activeStep, children }) {
  return children[activeStep];
}

Steps.defaultProps = {
  activeStep: 0,
  children: [],
};

export default ImportWizard;
