import React, { useEffect, useState } from 'react';
import {
  Alert, AppBar, Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, Grid, IconButton, InputLabel,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';

import { useStatus } from '../../hooks/useStatus';
import { fetchProductTree } from './fetchData';
import ProductsTable from './subtable';

const ProductTreeDetailModal = ({ open, onClose, onSubmit, id }) => {

  const { toggleStatus } = useStatus();
  const [refresh, setRefresh] = useState(false);

  const [description, setDescription] = useState('');
  const [project, setProject] = useState('');
  const [warehouse, setWarehouse] = useState('');
  const [products, setProducts] = useState([]);

  const [fetchingProductTree, setFetchingProductTree] = useState(false);

  useEffect(() => {
    if (id === null) {
      return;
    }
    const sessionID = Cookies.get('sessionID');
    setFetchingProductTree(true);
    fetchProductTree(
      sessionID,
      id
    ).then((data) => {
      console.log(data)
      if (data != null) {
        setDescription(data['ProductDescription']);
        setProject(data['Project']);
        setWarehouse(data['Warehouse']);
        setProducts(data['ProductTreeLines'].map((product) => ({ ...product, id: product.ChildNum })));
      }
    }).finally(() => {
      setFetchingProductTree(false);
    });
    setRefresh(false);
  }, [id, refresh]);

  const handleReset = () => {
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        onReset={handleCancel}
        id="stock-transfer-form"
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Estrutura de Produto
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                disabled
                label="Código"
                value={id}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                disabled
                label="Projeto"
                value={project}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                disabled
                label="Depósito"
                value={warehouse}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Descrição"
                multiline
                rows={2}
                value={description}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" mt={2}>Produtos</Typography>
              <ProductsTable data={products} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
          >Salvar</Button>
        </DialogActions>
      </Box>
      <Backdrop open={fetchingProductTree} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog >
  );
}

ProductTreeDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

export default ProductTreeDetailModal;

