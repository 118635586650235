import dayjs from "dayjs";
import { activities } from "layouts/TimeSheet/fetchData";

export async function fetchApontamentos(sessionID, employeeID, financialProject, startDate, endDate) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet');

  // apiUrl.searchParams.append('$top', 10);

  const select = [
    'DocNumber',
    'DateFrom',
    'DateTo',
    'FirstName',
    'LastName',
    'PM_TimeSheetLineDataCollection'
  ];

  // #### Nested query não quer funcionar :/
  /*const subselect = [
    'ActivityType',
    'Date',
    'StartTime',
    'Break',
    'EndTime',
    'EffectiveTime',
    'FinancialProject',
    'U_EASY_Obs',
    'U_EASY_Projeto',
  ]
  select.push(
    'PM_TimeSheetLineDataCollection ($select='.concat(
      subselect.join(',').concat(')')
    )
  )*/

  apiUrl.searchParams.append('$select', select.join(','));

  const filter = [
    'DateFrom ge '.concat(startDate),
    'DateTo le '.concat(endDate),
    'UserID eq '.concat(employeeID),
  ];
  apiUrl.searchParams.append('$filter', filter.join(' and '));

  const order = [
    'DateFrom asc',
    'DocNumber asc'
  ];
  apiUrl.searchParams.append('$orderby', order.join(', '));

  const data = [];

  await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then(json => json.value.map((item) => {
      const sub = item['PM_TimeSheetLineDataCollection'][0];
      if (sub['FinancialProject'] == financialProject) {
        Object.keys(sub).forEach((key) => item[key] = sub[key]);
        delete item['PM_TimeSheetLineDataCollection'];
        item['id'] = item['DocNumber'];
        item['Date'] = dayjs(item['Date']);
        item['Activity'] = activities.find((activity) => activity.id === item['ActivityType'])?.label;
        item['StartTime'] = item['StartTime'].length === 8 ? item['StartTime'].slice(0, 5) : item['StartTime'];
        item['Break'] = item['Break'].length === 8 ? item['Break'].slice(0, 5) : item['Break'];
        item['EndTime'] = item['EndTime'].length === 8 ? item['EndTime'].slice(0, 5) : item['EndTime'];
        item['EffectiveTime'] = item['EffectiveTime'].length === 8 ? item['EffectiveTime'].slice(0, 5) : item['EffectiveTime'];
        data.push(item);
      }
    }))
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
    return data;
}

export async function fetchTimeSheetsWithTotal(sessionID, startDate, endDate, employeeID = null, financialProject = null) {
  const data = {
    total: 0,
    quantity: 0,
    timesheets: [],
  };
  let repeat = true;
  let skip = 0;

  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('timesheetLines')/List");

  apiUrl.searchParams.append('dateFrom', `'${startDate}'`);
  apiUrl.searchParams.append('dateTo', `'${endDate}'`);
  apiUrl.searchParams.append('userID', employeeID || "'%'");
  apiUrl.searchParams.append('project', "'%'");
  if (financialProject) {
    apiUrl.searchParams.set('project', `'%${financialProject}%'`);
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', skip);
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=200'
      },
    })
      .then(response => response.json())
      .then(json => {
        json.value.map((item) => {
          item['id'] = item['DocNum'];
          item['Date'] = dayjs(item['Date']);
          item['Employee'] = `${item['UserID']} - ${item['FirstName']} ${item['LastName']}`;
          item['EmployeeName'] = `${item['FirstName']} ${item['LastName']}`;
          item['Project'] = item['FiProject'];
          item['Activity'] = item['Name'];
          item['Description'] = item['DSCRIPTION'];
          item['StartTime'] = String(item['StartTime']);
          item['StartTime'] = item['StartTime'].length < 4 ? '0'.repeat(4 - item['StartTime'].length).concat(item['StartTime']) : item['StartTime'];
          item['StartTime'] = item['StartTime'].slice(0, 2).concat(':', item['StartTime'].slice(2, 4));
          item['Break'] = String(item['Break']);
          item['Break'] = item['Break'].length < 4 ? '0'.repeat(4 - item['Break'].length).concat(item['Break']) : item['Break'];
          item['Break'] = item['Break'].slice(0, 2).concat(':', item['Break'].slice(2, 4));
          item['EndTime'] = String(item['EndTime']);
          item['EndTime'] = item['EndTime'].length < 4 ? '0'.repeat(4 - item['EndTime'].length).concat(item['EndTime']) : item['EndTime'];
          item['EndTime'] = item['EndTime'].slice(0, 2).concat(':', item['EndTime'].slice(2, 4));
          item['Local'] = item['U_Local'];
          item['Holiday'] = item['U_Feriado'];
          item['Retrabalho'] = item['U_Retrabalho'];
          item['Observation'] = item['U_EASY_Obs'];
          item['Created'] = item['U_Created'];
          item['CanEdit'] = dayjs().diff(dayjs(item['U_Created']), 'days') < 8;
          item['EffectiveTime'] = String(item['EffectTm']);
          item['EffectiveTime'] = item['EffectiveTime'].length < 4 ? '0'.repeat(4 - item['EffectiveTime'].length).concat(item['EffectiveTime']) : item['EffectiveTime'];
          item['EffectiveTime'] = item['EffectiveTime'].slice(0, 2).concat(':', item['EffectiveTime'].slice(2, 4));
          data.quantity++;
          data.total += dayjs(item['EffectiveTime'], 'HH:mm').diff(dayjs('00:00', 'HH:mm'), 'minute');
          data.timesheets.push(item);
        });
        repeat = json.value.length > 0;
        skip += json.value.length;
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }

  return data;
}

export async function fetchColaboradores(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
  
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProjetos(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'ProjectName,FinancialProject');
  apiUrl.searchParams.append('$filter', "ProjectStatus ne 'pst_Canceled'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);
  
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = item['ProjectName']
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}
