import React, { useEffect, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { AddCircleRounded, DoubleArrow, NotInterested, RemoveCircleRounded } from '@mui/icons-material';


const TransferSubForm = ({ line, onSubmit, onClose, onDelete, open }) => {

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [whs, setWhs] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [mu, setMu] = useState('');
  const [conferred, setConferred] = useState(0);

  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    if (line) {
      setName(line['ItemDescription']);
      setQuantity(parseFloat(line['Quantity']) - line['U_TextoLivre4']);
      setCode(line['ItemCode']);
      setWhs(line['FromWarehouseCode']);
      setMu(line['MeasureUnit']);
    } else if (shouldReset) {
      setShouldReset(false);
      handleReset();
    }
  }, [line]);

  const handleChangeConferred = (value) => {
    if (value === true) {
      setConferred(quantity);
    } else if (value === false) {
      setConferred(0);
    } else {
      setConferred(value);
    }
  }

  const handleReset = () => {
    setName('');
    setCode('');
    setWhs('');
    setQuantity(0);
    setMu('');
    setConferred(0);
  }

  const handleClose = () => {
    handleReset();
    onClose();
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setShouldReset(true);
    onDelete();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShouldReset(true);
    const data = {
      "LineNum": line['LineNum'],
      "ItemCode": code,
      "U_TextoLivre4": conferred,
    };
    onSubmit(data);
  }

  return (
    <Dialog
      open={open} onClose={handleClose}
      maxWidth="sm" fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleReset,
        onSubmit: handleSubmit,
        id: 'project-stage-form'
      }}
    >
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item sm={6}>
            <TextField fullWidth label="Item" value={code} disabled variant="standard" />
          </Grid>
          <Grid item sm={3}>
            <TextField fullWidth label="Depósito" value={whs} disabled variant="standard" />
          </Grid>
          <Grid item sm={3}>
            <TextField fullWidth label="Unidade Medida" value={mu} disabled variant="standard" />
          </Grid>
          <Grid item sm={4}>
            <TextField fullWidth label="Quantidade Transferida" value={quantity} disabled variant="standard" />
          </Grid>
          <Grid item sm={8}>
            <ValueField label="Quantidade Recebida" maxValue={quantity} value={conferred} onChange={handleChangeConferred} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" sx={{ display: 'none' }} onClick={handleDelete} variant="contained">Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" onClick={handleClose}>Cancelar</Button>
        <Button color="warning" disabled={conferred == 0} variant="contained" type="submit">Conferir</Button>
      </DialogActions>
    </Dialog>
  );
};

TransferSubForm.defaultProps = {
  line: null,
  onSubmit: () => { },
  open: false,
  onClose: () => { },
};

function ValueField({ label, maxValue, value, onChange }) {
  return (
    <TextField
      disabled={maxValue <= 0}
      type="number"
      fullWidth
      label={label}
      variant="outlined"
      onChange={(e) => onChange(e.target.value)}
      style={{ minWidth: '10rem' }}
      value={value}
      InputProps={{
        inputProps: { style: { textAlign: 'center' } },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              color="warning"
              disabled={value <= 0}
              onClick={() => onChange(false)}
            >
              <NotInterested />
            </IconButton>
            <IconButton
              color="warning"
              disabled={value <= 0}
              onClick={() => onChange(value - 1)}
            >
              <RemoveCircleRounded />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="warning"
              disabled={value >= maxValue}
              onClick={() => onChange(value + 1)}
            >
              <AddCircleRounded />
            </IconButton>
            <IconButton
              color="warning"
              disabled={value >= maxValue}
              onClick={() => onChange(true)}
            >
              <DoubleArrow />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default TransferSubForm;
