export const statuses = [
  { value: 'tYES', label: 'Ativo' },
  { value: 'tNO', label: 'Inativo' },
];

export const roles = [
  { value: null, label: 'Usuário' },
  { value: 'Manager', label: 'Gestor' },
];

export async function fetchEmployees(sessionID, costCenter=null, active=null, portalRole=null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  const select = [
    'EmployeeID',
    'EmployeeCode',
    'LastName',
    'FirstName',
    'MiddleName',
    'JobTitle',
    'eMail',
    'CostCenterCode',
    'Active',
    'U_Portal_Role',
    'U_EASY_ApontaEmLote',
  ];
  apiUrl.searchParams.append('$select', select.join(','));
  
  const filters = [];
  if (costCenter) {
    filters.push(`contains(CostCenterCode, '${costCenter}')`);
  }
  if (active) {
    filters.push(`Active eq '${active.value}'`);
  }
  if (portalRole) {
    filters.push(`U_Portal_Role eq '${portalRole.value}'`);
  }
  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }
  
  apiUrl.searchParams.append('$orderby', 'Active desc, EmployeeID asc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['EmployeeID'];
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`;
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchEmployee(sessionID, EmployeeID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo(${EmployeeID})`);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then((json) => {
      const item = json;
      return item;
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
}

export async function createEmployee(sessionID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
};

export async function deleteEmployee(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo(${id})`);

  const response = await fetch(apiUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return;
}

export async function editEmployee(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return;
}
