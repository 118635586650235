import React, { useEffect, useState } from 'react';
import {
  AppBar, Backdrop, Box, CircularProgress, Dialog, DialogActions,
  DialogContent, Divider, Grid, IconButton,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { fetchPurchaseOrder } from './fetchData';
import ProductsTable from './subtable';

const PurchaseOrderDetailModal = ({ open, onClose, onSubmit, id }) => {

  const { toggleStatus } = useStatus();
  const [refresh, setRefresh] = useState(false);

  const [docNum, setDocNum] = useState('');
  const [comments, setComments] = useState('');
  const [project, setProject] = useState('');
  const [card, setCard] = useState('');
  const [products, setProducts] = useState([]);
  const [updates, setUpdates] = useState(['10/10/2024', '09/10/2024', '08/10/2024', '07/10/2024', '06/10/2024', '05/10/2024', '04/10/2024']);

  const [fetchingPurchaseOrder, setFetchingPurchaseOrder] = useState(false);

  useEffect(() => {
    if (id === null) {
      return;
    }
    const sessionID = Cookies.get('sessionID');
    setFetchingPurchaseOrder(true);
    fetchPurchaseOrder(
      sessionID,
      id
    ).then((data) => {
      console.log(data)
      if (data != null) {
        setDocNum(data['DocNum']);
        setComments(data['Comments']);
        setProject(data['Project']);
        setCard(`${data['CardCode']} - ${data['CardName']}`);
        setProducts(data['DocumentLines'].map((product) => {
          const hist = product.U_EASY_INfoAdic && product.U_EASY_INfoAdic.split(';');
          return {
            ...product,
            id: product.LineNum,
            shipDate: product.ShipDate && dayjs(product.ShipDate).format('DD/MM/YYYY'),
            u_Grey_DtFat: product.U_Grey_DtFat && dayjs(product.U_Grey_DtFat).format('DD/MM/YYYY'),
            u_DataDeFaturamentoInicial: product.U_DataDeFaturamentoInicial && dayjs(product.U_DataDeFaturamentoInicial).format('DD/MM/YYYY'),
            u_EASY_INfoAdic: hist && product.U_EASY_INfoAdic.replace(/;/g, '\n'),
            hist_total: hist && hist.length,
          };
        }));
      }
    }).finally(() => {
      setFetchingPurchaseOrder(false);
    });
    setRefresh(false);
  }, [id, refresh]);

  const handleReset = () => {
    setDocNum('');
    setComments('');
    setProject('');
    setCard('');
    setProducts([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        onReset={handleCancel}
        id="stock-transfer-form"
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Pedido de Compra - {docNum}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2} mt={1} sx={{ alignItems: 'start' }}>
            <Grid container spacing={2} item xs={12} sm={10}>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Número"
                  value={docNum}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Projeto"
                  value={project}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  disabled
                  label="Fornecedor"
                  value={card}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Descrição"
                  multiline
                  rows={2}
                  value={comments}
                />
              </Grid>
            </Grid>
            <Grid item xs={0} sm={2} />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div" mt={2}>Produtos</Typography>
              <ProductsTable data={products} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Box>
      <Backdrop open={fetchingPurchaseOrder} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog >
  );
}

PurchaseOrderDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

export default PurchaseOrderDetailModal;

