
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box, Card, CircularProgress, FormControl, Grid, Icon,
  TextField,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import PurchaseOrdersDetailModal from './detail';
import PurchaseOrdersTable from './table';
import { fetchPurchaseOrders, fetchSuppliers } from './fetchData';
import dynTheme from 'assets/dynTheme';
import ImportWizard from './wizard';
import { exportTemplate } from './exportData';

let debounceTimer;
function debounce(func, delay) {
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

function PurchaseOrdersView() {

  const [refresh, setRefresh] = useState(false);
  const [docNum, setDocNum] = useState('');
  const [project, setProject] = useState('');
  const [cardCode, setCardCode] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());

  const [showForm, setShowForm] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const [showWizard, setShowWizard] = useState(false);

  const [orders, setOrders] = useState([]);
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [fetchingSuppliers, setFetchingSuppliers] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingSuppliers(true);
    fetchSuppliers(sessionID).then((data) => {
      if (data != null) {
        setSuppliers(data);
      }
    }).finally(() => {
      setFetchingSuppliers(false);
    });
  }, []);


  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingOrders(true);
    fetchPurchaseOrders(
      sessionID,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      docNum,
      project,
      cardCode?.id,
    ).then((data) => {
      if (data != null) {
        setOrders(data);
      }
    }).finally(() => {
      setFetchingOrders(false);
    });
  }, 1000), [refresh, docNum, project, cardCode, startDate, endDate]);

  const handleChangeDocNum = (e) => {
    setDocNum(e.target.value);
  }

  const handleChangeProject = (e) => {
    setProject(e.target.value);
  }

  const handleChangeCardCode = (e, value) => {
    e.preventDefault();
    setCardCode(value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  const handleDetail = (id) => {
    setDetailId(id);
    setShowForm(true);
  }

  const handleCloseForm = () => {
    setShowForm(false);
    setDetailId(null);
  }

  const handleSubmitForm = () => {
    setRefresh(!refresh);
  }

  const handleOpenWizard = (e) => {
    e.preventDefault();
    setShowWizard(true);
  }

  const handleCloseWizard = () => {
    setShowWizard(false);
  }

  const handleSubmitWizard = () => {
    setRefresh(!refresh);
  }

  const handleExportTemplate = (e) => {
    e.preventDefault();
    exportTemplate();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Pedidos de Compra
              </MDTypography>
            </Box>
            <Grid container spacing={2} style={{ width: 'auto' }}>
              <Grid item xs={12} md={4}>
                <MDButton disabled fullWidth variant="gradient" color="warning" >
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>add</Icon>
                  Novo
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton fullWidth onClick={handleOpenWizard} variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>upload</Icon>
                  Importação
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDButton fullWidth onClick={handleExportTemplate} variant="gradient" color="warning">
                  <Icon sx={{ fontWeight: "bold", marginRight: 1 }}>tablehart</Icon>
                  Template
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={2} padding={2}
            >
              <Grid item mt={2}>
                <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="docNum" label="Nº do Pedido"
                        value={docNum} onChange={handleChangeDocNum}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="project" label="Projeto" value={project}
                        onChange={handleChangeProject}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Autocomplete
                        fullWidth
                        loadingText={<CircularProgress color="primary" size={20} />}
                        loading={fetchingSuppliers}
                        onChange={handleChangeCardCode}
                        options={suppliers}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Fornecedor"
                          />
                        )}
                        value={cardCode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-inicio" label="Data Inicial" onChange={handleChangeStartDate} value={startDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ width: '100%', overflowX: 'scroll' }}>
                <PurchaseOrdersTable
                  data={orders}
                  isLoading={fetchingOrders}
                  onEdit={handleDetail}
                />
              </Grid>
            </Grid>
            <PurchaseOrdersDetailModal
              id={detailId}
              open={showForm}
              onClose={handleCloseForm}
              onSubmit={handleSubmitForm}
            />
            <ImportWizard
              open={showWizard}
              onClose={handleCloseWizard}
              onSubmit={handleSubmitWizard}
            />
          </ThemeProvider>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}


export default PurchaseOrdersView;
