import dayjs from 'dayjs';

export const statuses = [
  { simple: 'R', id: 'boposReleased', label: 'Liberada' },
  { simple: 'L', id: 'boposClosed', label: 'Fechada' },
  { simple: 'P', id: 'boposPlanned', label: 'Planejada' },
  { simple: 'C', id: 'boposCanceled', label: 'Cancelada' },
];

export async function fetchProductionOrders(sessionID, startDate = null, endDate = null, docNumber = null, item = null, description = null, status = null) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders');
  let data = [];
  let repeat = true;

  const select = [
    'AbsoluteEntry',
    'DocumentNumber',
    'ProductionOrderStatus',
    'ItemNo',
    'ProductDescription',
    'Project',
    'PostingDate',
    'StartDate',
    'DueDate',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [];
  if (docNumber) {
    filters.push(`contains(DocumentNumber,'${docNumber}')`);
  } else {
    if (startDate) {
      filters.push(`StartDate ge '${startDate}'`);
    }
    if (endDate) {
      filters.push(`DueDate le '${endDate}'`);
    }
    if (item) {
      filters.push(`contains(ItemNo,'${item}')`);
    }
    if (description) {
      filters.push(`contains(ProductDescription,'${description}')`);
    }
    if (status) {
      filters.push(`ProductionOrderStatus eq '${status}'`);
    }
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  apiUrl.searchParams.append('$orderby', 'AbsoluteEntry desc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsoluteEntry'];
            item['created'] = dayjs(item['PostingDate']);
            item['start'] = dayjs(item['StartDate']);
            item['due'] = dayjs(item['DueDate']);
            item['Status'] = item['ProductionOrderStatus'] ? statuses.find(status => status.id === item['ProductionOrderStatus'])?.label : item['ProductionOrderStatus'];
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchOrder(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders(${id})`);

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  return data;
}

export async function updateProductionOrder(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductionOrders(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return null;
}
