import { AddCircleRounded, DoubleArrow, NotInterested, RemoveCircleRounded } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react';

function BatchFormTable({ batches, values, isFetching, isMaxed, shouldHighlight, onChange }) {

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Lote</TableCell>
          <TableCell>Disponível</TableCell>
          <TableCell align="center">Transferir</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isFetching ? (
          <TableRow>
            <TableCell colSpan={3} align="center">
              <CircularProgress color="warning" />
            </TableCell>
          </TableRow>
        ) :
          (batches.length > 0 ? batches.map((batch, index) => (
            <TableRow key={index} className={shouldHighlight(batch) ? 'Mui-selected' : ''}>
              <TableCell sx={shouldHighlight(batch) ? { fontWeight: 'bold', color: 'primary.main' } : {}}>{batch['BatchNum']}</TableCell>
              <TableCell>{batch['Quantity']}</TableCell>
              <TableCell align="center">
                <ValueField maxValue={batch['Quantity']} onChange={(value) => onChange(index, value)} isMaxed={isMaxed} value={values[index]} />
              </TableCell>
            </TableRow>
          )) : <TableRow><TableCell align="center" colSpan={3} variant="footer">Nenhum lote encontrado</TableCell></TableRow>)
        }
      </TableBody>
    </Table>
  )
}

function ValueField({ maxValue, isMaxed, value, onChange }) {
  return (
    <TextField
      disabled={maxValue <= 0}
      type="number"
      fullWidth
      variant="outlined"
      onChange={(e) => onChange(e.target.value)}
      style={{ minWidth: '10rem' }}
      value={value}
      InputProps={{
        inputProps: { style: { textAlign: 'center' } },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              color="primary"
              disabled={value <= 0}
              onClick={() => onChange(false)}
            >
              <NotInterested />
            </IconButton>
            <IconButton
              color="primary"
              disabled={value <= 0}
              onClick={() => onChange(value - 1)}
            >
              <RemoveCircleRounded />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="primary"
              disabled={value >= maxValue}
              onClick={() => onChange(value + 1)}
            >
              <AddCircleRounded />
            </IconButton>
            <IconButton
              color="primary"
              disabled={value >= maxValue}
              onClick={() => onChange(true)}
            >
              <DoubleArrow />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default BatchFormTable;
