import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import dataGridText from "assets/dataGridText";

const columns = [
  { field: 'index', headerName: 'Linha', width: 50, editable: false, },
  { field: 'DocNum', headerName: 'ID', width: 50, editable: false, },
  { field: 'FromWarehouse', headerName: 'De', width: 100, editable: false, },
  { field: 'ToWarehouse', headerName: 'Para', width: 100, editable: false, },
  { field: 'DocDate', headerName: 'Abertura', width: 100, type: 'date', editable: false, },
  { field: 'DueDate', headerName: 'Vencimento', width: 100, type: 'date', editable: true, },
  { field: 'Comments', headerName: 'Comentários', width: 150, editable: false, },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    width: 500,
    renderCell: (params) => {
      if (params.row.status === true) {
        return <Alert severity="success" variant="outlined">Salvo</Alert>;
      }
      else if (params.row.status === false) {
        return <Alert severity="warning" variant="standard">Salvando</Alert>;
      }
      else if (params.row.status) {
        return <Alert severity="error" variant="filled">Erro: {params.row.status}</Alert>;
      }
      return <Alert severity="info" variant="outlined">Não salvo</Alert>;
    },
  },
];

export default function StepSave({ value }) {

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                saved: false,
              },
            },
          }}
          rows={data}
          columns={columns}
          isCellEditable={() => false}
          isRowSelectable={() => false}
          editMode="row"
          style={{ height: '50vh' }}
          localeText={dataGridText}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}