import React, { useEffect, useState } from 'react';
import {
  Box, Button, ButtonGroup, Card, CircularProgress,
  Grid,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Cookies from 'js-cookie';

import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useStatus } from 'hooks/useStatus';
import { auth } from '../../firebase/firebase';
import dynTheme from 'assets/dynTheme';
import { fetchOrganogramas } from './fetchdata';
import { OpenInNew } from '@mui/icons-material';

const isManager = Cookies.get('logemployeerole') === 'Manager';

function OrganogramaView() {

  const [idToken, setIdToken] = useState(null);
  const [orgs, setOrgs] = useState({});
  const { toggleStatus } = useStatus();

  useEffect(() => {
    const checkToken = async () => {
      await auth.authStateReady();
      if (!auth.currentUser) {
        window.alert('Sessão expirada. Por favor, faça login novamente.');
        window.location.href = '/Authentication';
      }
      const tokenId = await auth.currentUser.getIdToken(true);
      setIdToken(tokenId);
    }

    checkToken();
  }, []);

  useEffect(() => {
    if (idToken) {
      fetchOrganogramas(idToken, Cookies.get('logemployeeID'), Cookies.get('logemployeecostcenter'))
        .then((data) => {
          if (data) {
            setOrgs(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [idToken]);

  const handleClick = (e) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    window.open(`https://org.greylogix.dev/orgs/${id}?idToken=${idToken}`, '_blank');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              <MDTypography variant="h5" color="white">
                Organograma Greylogix
              </MDTypography>
            </Box>
          </MDBox>
          <ThemeProvider theme={dynTheme}>
            <Grid
              container direction="column" justifyContent="center" alignItems="stretch"
              spacing={1} padding={4}
            >
              {idToken ? Object.keys(orgs).map((id) => (
                <Grid item key={id}>
                  <Button
                    id={id}
                    variant="text"
                    color="warning"
                    onClick={handleClick}
                    endIcon={<OpenInNew />}
                  >
                    {orgs[id]}
                  </Button>
                </Grid>
              )) : (
                <CircularProgress color='warning' />
              )
              }
            </Grid>
          </ThemeProvider>
        </Card>
      </MDBox >
    </DashboardLayout >
  );
}


export default OrganogramaView;
