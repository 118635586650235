import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions,
  DialogContent, Divider, FormControlLabel, Grid, IconButton,
  TextField, Toolbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, Send } from '@mui/icons-material';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { fetchOrder } from './fetchData';
import ItemsTable from './subtable';

const ProductionOrderDetailModal = ({ open, onClose, onSubmit, id }) => {

  const [postingDate, setPostingDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [project, setProject] = useState('');
  const [item, setItem] = useState('');
  const [description, setDescription] = useState('');
  const [plannedQuantity, setPlannedQuantity] = useState('');
  const [completedQuantity, setCompletedQuantity] = useState('');
  const [rejectedQuantity, setRejectedQuantity] = useState('');
  const [warehouse, setWarehouse] = useState('');
  const [um, setUm] = useState('');
  const [items, setItems] = useState([]);

  const { status, toggleStatus } = useStatus();

  const [fetchingOrder, setFetchingOrder] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (open && id) {
      setFetchingOrder(true);
      const sessionID = Cookies.get('sessionID');
      fetchOrder(sessionID, id)
        .then((response) => {
          setPostingDate(dayjs(response.PostingDate).format('DD/MM/YYYY'));
          setStartDate(dayjs(response.StartDate).format('DD/MM/YYYY'));
          setDueDate(dayjs(response.DueDate).format('DD/MM/YYYY'));
          setProject(response.Project);
          setItem(response.ItemNo);
          setDescription(response.ProductDescription);
          setPlannedQuantity(response.PlannedQuantity);
          setCompletedQuantity(response.CompletedQuantity);
          setRejectedQuantity(response.RejectedQuantity);
          setWarehouse(response.Warehouse);
          setUm(response.InventoryUOM);
          setItems(response.ProductionOrderLines.map((item) => ({
            ...item,
            id: item.LineNumber,
          })));
        })
        .catch((error) => {
          console.error('Erro:', error);
        })
        .finally(() => setFetchingOrder(false));
    }
  }, [open, id, refresh]);

  const handleReset = () => {
    setPostingDate('');
    setStartDate('');
    setDueDate('');
    setProject('');
    setItem('');
    setDescription('');
    setPlannedQuantity('');
    setCompletedQuantity('');
    setRejectedQuantity('');
    setWarehouse('');
    setItems([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Ordem de Produção - {id}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Nº OP" value={id} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField fullWidth label="Projeto" value={project} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Data Criação" value={postingDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Data Início" value={startDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Data Vencimento" value={dueDate} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField fullWidth label="Item" value={item} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField fullWidth label="Descrição" value={description} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Depósito" value={warehouse} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={1}>
            <TextField fullWidth label="UM" value={um} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Planejado" value={plannedQuantity} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Produzido" value={completedQuantity} disabled variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <TextField fullWidth label="Rejeitado" value={rejectedQuantity} disabled variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <ItemsTable data={items} />
          </Grid>
        </Grid>
      </DialogContent>
      <Backdrop open={fetchingOrder} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

ProductionOrderDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

export default ProductionOrderDetailModal;
