import React, { useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Step, StepLabel, Stepper,
} from '@mui/material';

import { updateProductionOrder } from '../fetchData'
import StepMap from './stepMap';
import StepSelect from './stepSelect';
import StepSave from './stepSave';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import StepFinish from './stepFinish';

function BatchWizard({ open, onClose, onSubmit }) {

  const steps = [
    'Selecione as OPs',
    'Mapeie os campos',
    'Salve as OPs',
    'Finalizando a correção',
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [errorStep, setErrorStep] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeData = (value) => {
    setData(value);
    if (errorStep === activeStep) {
      setErrorStep(null);
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setData([]);
    setErrorStep(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    handleClose(e);
  }

  const handleClose = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data);
    handleReset();
    handleClose(e);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let error = false;
    const sessionID = Cookies.get('sessionID');
    for (let index = 0; index < data.length; index++) {
      const row = data[index];
      row.status = false;
      setData([...data]);

      let sucess = await updateProductionOrder(
        sessionID,
        row.AbsoluteEntry,
        {
          StartDate: dayjs(row.StartDate).format('YYYY-MM-DD'),
          DueDate: dayjs(row.DueDate).format('YYYY-MM-DD'),
        }
      ).then(() => {
        row.status = true;
        setData([...data]);
        return true;
      }).catch((error) => {
        if (typeof error === 'string') {
          row.status = error;
        } else {
          row.status = error.error.message.value;
        }
        setData([...data]);
        console.log(error);
        setErrorStep(1);
      });
      if (!sucess) {
        error = true;
      }
    }
    setIsLoading(false);
    if (!error) {
      handleNext();
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" scroll="paper" open={open} onClose={handleClose}>
      <DialogTitle>{steps[activeStep]}</DialogTitle>
      <DialogContent dividers>
        <Steps activeStep={activeStep}>
          <StepSelect onChange={handleChangeData} />
          <StepMap onChange={handleChangeData} value={data} />
          <StepSave value={data} />
          <StepFinish value={data} />
        </Steps>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
              <Step>
                <StepLabel>Selecionar</StepLabel>
              </Step>
              <Step>
                <StepLabel error={errorStep === 1}>Mapear</StepLabel>
              </Step>
              <Step>
                <StepLabel>Salvar</StepLabel>
              </Step>
              <Step>
                <StepLabel>Finalizar</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              color="error" disabled={isLoading || activeStep + 1 === steps.length}
              onClick={handleCancel} variant="contained"
            >Cancelar</Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading || activeStep === 0 || activeStep + 1 === steps.length}
              onClick={handleBack}
            >Anterior</Button>
          </Grid>
          <Grid item>
            <Steps activeStep={activeStep}>
              <Button disabled={data.length === 0} onClick={handleNext}>Próximo</Button>
              <Button onClick={handleNext}>Próximo</Button>
              <>
                <Button color="secondary" disabled={isLoading} onClick={handleNext} variant="outlined">Pular</Button>
                <Button disabled={isLoading || errorStep !== null} onClick={handleSave} variant="contained">Salvar</Button>
              </>
              <>
                <Button color="secondary" onClick={handleReset} variant="outlined">Nova</Button>
                <Button onClick={handleSubmit} variant="contained">Finalizar</Button>
              </>
            </Steps>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

BatchWizard.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
};

function Steps({ activeStep, children }) {
  return children[activeStep];
}

Steps.defaultProps = {
  activeStep: 0,
  children: [],
};

export default BatchWizard;
