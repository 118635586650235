export async function fetchBIs(idToken, employeeID, costCenter) {
  const apiURL = new URL('https://bi.greylogix.dev/bis');

  apiURL.searchParams.append('idToken', idToken);
  apiURL.searchParams.append('employeeID', employeeID);
  apiURL.searchParams.append('costCenter', costCenter);

  return await fetch(apiURL)
    .then(response => response.json())
    .catch(error => {
      console.error(error)
      throw error;
    });
}