import React, { memo, useCallback } from 'react';
import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Visibility } from '@mui/icons-material';

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.isLoading === newProps.isLoading &&
    oldProps.data.length === newProps.data.length
  )
}

const PurchaseOrderTable = memo(function InventoryGenExitsTable({ data, onEdit, isLoading }) {

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('id');
    if (id) {
      onEdit(id);
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nº</TableCell>
            <TableCell>Projeto</TableCell>
            <TableCell>Criado</TableCell>
            <TableCell>Atualizado</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Fornecedor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
            >
              <TableCell><IconButton id={item.id} onClick={handleEdit}><Visibility /></IconButton></TableCell>
              <TableCell component="th" scope="row">
                {item.DocNum}
              </TableCell>
              <TableCell>{item.Project}</TableCell>
              <TableCell>{item.createDate.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.updateDate.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.dueDate.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{item.Card}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhuma Estrutura de Produto encontrada
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}, arePropsEqual);

export default PurchaseOrderTable;
