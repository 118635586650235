import { useEffect, useState } from "react";
import { Alert, Autocomplete, Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from 'js-cookie';

import { fetchWarehouses } from '../fetchData';

import dataGridText from "assets/dataGridText";

const columns = [
  { field: 'id', headerName: 'Semiacabado', width: 200, editable: false, },
  { field: 'project', headerName: 'Projeto', width: 150, editable: false, },
  { field: 'warehouse', headerName: 'Depósito', width: 150, editable: true, },
  { field: 'distributionRule', headerName: 'Setor', width: 150, editable: false, },
  { field: 'lines', headerName: 'Linhas', width: 100, editable: false, },
  { field: 'overwrite', headerName: 'Trocar?', width: 100, editable: true, type: 'boolean' },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    width: 300,
    renderCell: (params) => {
      if (params.row.status === true) {
        return <Alert severity="success" variant="outlined">Salvo</Alert>;
      }
      else if (params.row.status) {
        return <Alert severity="error" variant="filled" style={{ overflow: 'auto' }}>Erro: {params.row.status}</Alert>;
      }
      return null;
    },
  },
];

export default function StepMap({ onChange, value }) {

  const [data, setData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [warehouse, setWarehouse] = useState(null);
  const [overwrite, setOverwrite] = useState(false);

  const [fetchingWarehouses, setFetchingWarehouses] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingWarehouses(true);
    fetchWarehouses(sessionID)
      .then((data) => {
        if (data !== null) {
          setWarehouses(data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setFetchingWarehouses(false));
  }, []);

  useEffect(() => {
    setData(value);
  }, [value]);

  useEffect(() => {
    if (warehouse) {
      const newData = data.map((row) => {
        if (row.status === true) {
          return row;
        }
        return {
          ...row,
          warehouse: warehouse.value,
        }
      });
      onChange(newData);
    }
  }, [warehouse]);

  useEffect(() => {
    const newData = data.map((row) => {
      if (row.status === true || row.overwrite === null) {
        return row;
      }
      return {
        ...row,
        overwrite,
      }
    });
    onChange(newData);
  }, [overwrite]);

  const handleChangeData = (newData, oldData) => {
    const index = data.findIndex((row) => row.id === oldData.id);
    console.log(newData.overwrite, oldData.overwrite)
    if (index >= 0 && oldData.status !== true && (oldData.overwrite === newData.overwrite || oldData.overwrite !== null)) {
      const updatedData = [...data];
      updatedData[index] = newData;
      onChange(updatedData);
    }
    return oldData;
  }

  const handleChangeWarehouse = (e, value) => {
    setWarehouse(value);
  }

  const handleChangeOverwrite = (e) => {
    setOverwrite(e.target.checked);
  }

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              fullWidth
              label="De"
              options={warehouses}
              getOptionLabel={(option) => option.label}
              loadingText={<CircularProgress color="primary" size={20} />}
              loading={fetchingWarehouses}
              value={warehouse}
              onChange={handleChangeWarehouse}
              renderInput={(params) => <TextField {...params} label="Depósito" />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControlLabel
              control={<Checkbox checked={overwrite} onChange={handleChangeOverwrite} />}
              label="Sobrescrever todos?"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          processRowUpdate={handleChangeData}
          onProcessRowUpdateError={(error) => console.error(error)}
          columns={columns}
          isCellEditable={(params) => !params.row.saved}
          isRowSelectable={() => false}
          editMode="row"
          style={{ height: '50vh' }}
          localeText={dataGridText}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}
