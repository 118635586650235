import dayjs from "dayjs";

export let activities = [
  { id: 1, label: 'Assistência' },
  { id: 2, label: 'Ausência' },
  { id: 3, label: 'Capacitação/treinamento' },
  { id: 4, label: 'Comissionamento' },
  { id: 5, label: 'COMOS' },
  { id: 6, label: 'Compras' },
  { id: 7, label: 'DDE Automação' },
  { id: 8, label: 'DDE Elétrica' },
  { id: 9, label: 'DDE Instrumentação' },
  { id: 10, label: 'DDE Mecânica' },
  { id: 11, label: 'DDE Modelamento 3D' },
  { id: 12, label: 'DDE Processo' },
  { id: 13, label: 'DDE Tubulação' },
  { id: 14, label: 'Desenvolvimento de tela' },
  { id: 15, label: 'Desenvolvimento software' },
  { id: 16, label: 'Deslocamento' },
  { id: 17, label: 'Diagrama Eletromecânico' },
  { id: 18, label: 'Elaboração de manuais e Relatórios' },
  { id: 19, label: 'Férias' },
  { id: 20, label: 'Folga / Atestado' },
  { id: 21, label: 'Folga de campo' },
  { id: 22, label: 'Gestão' },
  { id: 23, label: 'Montagem' },
  { id: 24, label: 'Operação Assistida' },
  { id: 25, label: 'Reunião Interna' },
  { id: 26, label: 'Reunião Projeto' },
  { id: 27, label: 'Sem alocação em projeto' },
  { id: 28, label: 'Suporte Comercial' },
  { id: 29, label: 'TAF' },
  { id: 30, label: 'Visita comercial' },
  { id: 31, label: 'Visita técnica' }
];

export const locals = ["Acesso Remoto", "Campo", "Escritório", "Fábrica"];

export const retrabalhos = ['Engenharia', 'Montagem', 'Cliente', 'Fornecedor'];

export async function fetchAcitvities(sessionID) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('sql01')/List");

  return fetch(apiUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Basic ${sessionID}`,
      Prefer: 'odata.maxpagesize=500',
    },
  })
    .then(response => response.json())
    .then(json => {
      activities = [];
      json.value.forEach((item) => {
        activities.push({
          id: item['TaskID'],
          label: item['Name']
        });
      });
    });
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProjectsNames(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Projects');
  let data = {};
  let repeat = true;

  apiUrl.searchParams.append('$select', 'Code, Name');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', Object.keys(data).length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data[item['Code']] = item['Name'];
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProjects(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'AbsEntry,FinancialProject,ProjectName,PM_StagesCollection');

  apiUrl.searchParams.append('$filter', "ProjectStatus ne 'pst_Canceled'");

  const projects = await fetchProjectsNames(sessionID);

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsEntry'];
            item['label'] = projects[item['ProjectName']] || item['ProjectName'];
            item['PM_StageCollection'] = item['PM_StagesCollection'].map((stage) => {
              stage['id'] = stage['StageID'];
              stage['label'] = String(stage['Task']).concat(' - ', stage['Description']);
              return stage;
            });
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProjectID(sessionID, ProjectName) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = null;

  apiUrl.searchParams.append('$select', 'AbsEntry');
  apiUrl.searchParams.append('$filter', `ProjectName eq '${ProjectName}'`);

  await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.value.length > 0) {
        data = json.value[0]['AbsEntry'];
      }
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });

  return data;
}

export async function fetchTimeSheetsWithTotal(sessionID, startDate, endDate, employeeID = null, financialProject = null) {
  const data = {
    total: 0,
    quantity: 0,
    timesheets: [],
  };
  let repeat = true;
  let skip = 0;

  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('timesheetLines')/List");

  apiUrl.searchParams.append('dateFrom', `'${startDate}'`);
  apiUrl.searchParams.append('dateTo', `'${endDate}'`);
  apiUrl.searchParams.append('userID', employeeID || "'%'");
  apiUrl.searchParams.append('project', "'%'");
  if (financialProject) {
    apiUrl.searchParams.set('project', `'%${financialProject}%'`);
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', skip);
    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=200'
      },
    })
      .then(response => response.json())
      .then(json => {
        json.value.map((item) => {
          item['id'] = item['DocNum'];
          item['Date'] = dayjs(item['Date']).format('DD/MM/YYYY');
          item['Employee'] = `${item['UserID']} - ${item['FirstName']} ${item['LastName']}`;
          item['EmployeeName'] = `${item['FirstName']} ${item['LastName']}`;
          item['Project'] = item['FiProject'];
          item['Activity'] = item['Name'];
          item['Description'] = item['DSCRIPTION'];
          item['StartTime'] = String(item['StartTime']);
          item['StartTime'] = item['StartTime'].length < 4 ? '0'.repeat(4 - item['StartTime'].length).concat(item['StartTime']) : item['StartTime'];
          item['StartTime'] = item['StartTime'].slice(0, 2).concat(':', item['StartTime'].slice(2, 4));
          item['Break'] = String(item['Break']);
          item['Break'] = item['Break'].length < 4 ? '0'.repeat(4 - item['Break'].length).concat(item['Break']) : item['Break'];
          item['Break'] = item['Break'].slice(0, 2).concat(':', item['Break'].slice(2, 4));
          item['EndTime'] = String(item['EndTime']);
          item['EndTime'] = item['EndTime'].length < 4 ? '0'.repeat(4 - item['EndTime'].length).concat(item['EndTime']) : item['EndTime'];
          item['EndTime'] = item['EndTime'].slice(0, 2).concat(':', item['EndTime'].slice(2, 4));
          item['Local'] = item['U_Local'];
          item['Holiday'] = item['U_Feriado'];
          item['Retrabalho'] = item['U_Retrabalho'];
          item['Observation'] = item['U_EASY_Obs'];
          item['Created'] = item['U_Created'];
          item['CanEdit'] = dayjs().diff(dayjs(item['U_Created']), 'days') < 8;
          item['EffectiveTime'] = String(item['EffectTm']);
          item['EffectiveTime'] = item['EffectiveTime'].length < 4 ? '0'.repeat(4 - item['EffectiveTime'].length).concat(item['EffectiveTime']) : item['EffectiveTime'];
          item['EffectiveTime'] = item['EffectiveTime'].slice(0, 2).concat(':', item['EffectiveTime'].slice(2, 4));
          data.quantity++;
          data.total += dayjs(item['EffectiveTime'], 'HH:mm').diff(dayjs('00:00', 'HH:mm'), 'minute');
          data.timesheets.push(item);
        });
        repeat = json.value.length > 0;
        skip += json.value.length;
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }

  return data;
}

export async function fetchTimeSheet(sessionID, id) {
  let data = null;

  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet(${id})`);
  const select = [
    'DocNumber',
    'DateFrom',
    'DateTo',
    'UserID',
    'FirstName',
    'LastName',
    'PM_TimeSheetLineDataCollection'
  ];
  apiUrl.searchParams.append('$select', select.join(','));

  await fetch(
    apiUrl,
    {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    }
  )
    .then(response => response.json())
    .then(item => {
      const sub = item['PM_TimeSheetLineDataCollection'][0];
      Object.keys(sub).forEach((key) => item[key] = sub[key]);
      delete item['PM_TimeSheetLineDataCollection'];
      data = item;
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });

  return data;
}

export async function fetchTimeSheetsWIP(sessionID, startDate, endDate, employee, project) {
  const crossjoin = [
    'ProjectManagementTimeSheet',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection',
  ]
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin('.concat(crossjoin.join(','), ')'));
  let data = [];
  let repeat = true;

  const select = [
    'ProjectManagementTimeSheet/AbsEntry',
    'ProjectManagementTimeSheet/UserId',
    'ProjectManagementTimeSheet/FirstName',
    'ProjectManagementTimeSheet/LastName',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/Date',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/StartTime',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/EndTime',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/Break',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/EffectiveTime',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/FinancialProject',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/ActivityType',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/U_EASY_Obs',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/U_Local',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/U_Feriado',
    'ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/U_Created',

  ];
  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [
    'ProjectManagementTimesheet'
      `ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/Date ge '${startDate}'`,
    `ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/Date le '${endDate}'`
  ];
  if (employee !== null) {
    filters.push(`ProjectManagementTimeSheet/UserId eq '${employee}'`);
  }
  if (project !== null) {
    filters.push(`ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection/FinancialProject eq '${project}'`);
  }
  apiUrl.searchParams.append('$filter', filters.join(' and '));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=100'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = {};
            const pm = item['ProjectManagementTimeSheet'];
            line['id'] = pm['AbsEntry'];
            const ts = item['ProjectManagementTimeSheet/PM_TimeSheetLineDataCollection'];
            line['date'] = dayjs(ts['Date']).format('DD/MM/YYYY');
            line['employee'] = `${pm['UserId']} - ${pm['FirstName']} ${pm['LastName']}`;
            line['project'] = ts['FinancialProject'];
            line['activity'] = ts['ActivityType'];
            line['start'] = ts['StartTime'];
            line['break'] = ts['Break'];
            line['end'] = ts['EndTime'];
            line['total'] = ts['EffectiveTime'];
            line['locale'] = ts['U_Local'];
            line['holiday'] = ts['U_Feriado'];
            line['observation'] = ts['U_EASY_Obs'];
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function createTimeSheet(sessionID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet');

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
};

export async function deleteTimeSheet(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet(${id})`);

  const response = await fetch(apiUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (!response.status === 204) {
    throw await response.json();
  }
  return;
}

export async function editTimeSheet(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagementTimeSheet(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.status === 204) {
    throw await response.json();
  }
  return;
}
