
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchProductionOrders, statuses } from '../fetchData';
import Cookies from 'js-cookie';
import dataGridText from 'assets/dataGridText';
import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';

let debounceTimer;
function debounce(func, delay) {
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceTimer = null;
    }, delay);
  }
}

export default function StepSelect({ onChange }) {
  const columns = [
    { field: 'index', headerName: 'Linha', width: 50, editable: false, },
    { field: 'DocumentNumber', headerName: 'OP', width: 100, editable: false, },
    { field: 'ItemNo', headerName: 'Item', width: 100, editable: false, },
    { field: 'Status', headerName: 'Status', width: 100, editable: false, },
    { field: 'StartDate', headerName: 'Abertura', width: 150, type: 'date', editable: false, },
    { field: 'DueDate', headerName: 'Vencimento', width: 150, type: 'date', editable: true, },
    { field: 'ProductDescription', headerName: 'Descrição', width: 300, editable: false, },
  ];

  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);

  const [fetchingRequests, setFetchingRequests] = useState(false);

  const [docNumber, setDocNumber] = useState('');
  const [item, setItem] = useState('');
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));

  useEffect(debounce(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingRequests(true);
    fetchProductionOrders(
      sessionID,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      docNumber,
      item,
      description,
      status?.id,
    ).then((rows) => {
      if (rows != null) {
        const lines = [];
        rows.forEach((row, index) => {
          lines.push({
            ...row,
            index: index,
            StartDate: dayjs(row.StartDate).toDate(),
            DueDate: dayjs(row.DueDate).toDate(),
          });
        });
        setData(lines);
      }
    }).finally(() => {
      if (!debounceTimer) {
        setFetchingRequests(false);
      }
    });
  }, 1000), [docNumber, item, status, description, startDate, endDate]);

  useEffect(() => {
    onChange(data.filter((row) => selection.includes(row.id)));
  }, [selection]);

  const handleChangeDocNumber = (e) => {
    setDocNumber(e.target.value);
  }

  const handleChangeFromWarehouse = (e) => {
    setItem(e.target.value);
  }

  const handleChangeToWarehouse = (e) => {
    setStatus(e.target.value);
  }

  const handleChangeComments = (e) => {
    setDescription(e.target.value);
  }

  const handleChangeStatus = (e, value) => {
    e.preventDefault();
    setStatus(value);
  }

  const handleChangeStartDate = (date) => {
    if (date) {
      setStartDate(date);
    }
  }

  const handleChangeEndDate = (date) => {
    if (date) {
      setEndDate(date);
    }
  }

  const handleChangeSelection = (selectionModel) => {
    setSelection(selectionModel);
  }

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="stretch" spacing={1}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="docNumber" label="Número da OP"
                value={docNumber} onChange={handleChangeDocNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="item" label="Item" value={item}
                onChange={handleChangeFromWarehouse}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <Autocomplete
                id="status" options={statuses}
                value={status} onChange={handleChangeStatus}
                renderInput={(params) => <TextField {...params} label="Status" />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl fullWidth>
              <TextField
                id="description" label="Descrição"
                value={description} onChange={handleChangeComments}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker id="data-inicio" label="Data Inicial" onChange={handleChangeStartDate} value={startDate} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker id="data-final" label="Data Final" onChange={handleChangeEndDate} value={endDate} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          columns={columns}
          rowSelectionModel={selection}
          onRowSelectionModelChange={handleChangeSelection}
          style={{ height: '50vh' }}
          loading={fetchingRequests}
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnSorting
          localeText={dataGridText}
        />
      </Grid>
    </Grid>
  );
}
