import React, { useEffect, useState } from 'react';
import {
  Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel,
  FormHelperText, Grid, InputLabel, TextField, Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { activities, locals, fetchTimeSheet, createTimeSheet, deleteTimeSheet, editTimeSheet, retrabalhos } from './fetchData';

const TimeSheetFormModal = ({ open, onClose, onSubmit, employees, projects, fixedEmployee, id }) => {

  const [employee, setEmployee] = useState(fixedEmployee);
  const [local, setLocal] = useState(null);
  const [project, setProject] = useState(null);
  const [stage, setStage] = useState(null);
  const [date, setDate] = useState(dayjs());
  const [holiday, setHoliday] = useState(false);
  const [retrabalho, setRetrabalho] = useState(null);
  const [startTime, setStartTime] = useState(dayjs().set('hour', 8).set('minute', 0));
  const [endTime, setEndTime] = useState(dayjs().set('hour', 18).set('minute', 0));
  const [interval, setInterval] = useState(dayjs().set('hour', 1).set('minute', 0));
  const [observations, setObservations] = useState('');
  const [created, setCreated] = useState(null);
  const [lineID, setLineID] = useState(null);

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [stages, setStages] = useState([]);

  const [fetchingTimesheet, setFetchingTimesheet] = useState(false);

  useEffect(() => {
    if (id) {
      setFetchingTimesheet(true);
      const sessionID = Cookies.get('sessionID');
      fetchTimeSheet(sessionID, id).then((response) => {
        setEmployee(''.concat(response['UserID'], ' - ', response['FirstName'], ' ', response['LastName']));
        setLocal(response['U_Local']);
        setProject(response['FinancialProject']);
        setStage({
          id: response['ActivityType'],
          label: activities.find((activity) => activity.id === response['ActivityType'])?.label
        });
        setDate(dayjs(response['Date']));
        setHoliday(response['U_Feriado']);
        setRetrabalho(response['U_Retrabalho']);
        const start = response['StartTime'];
        setStartTime(dayjs(response['Date']).hour(start.split(':')[0]).minute(start.split(':')[1]));
        const end = response['EndTime'];
        setEndTime(dayjs(response['Date']).hour(end.split(':')[0]).minute(end.split(':')[1]));
        const interval = response['Break'];
        if (interval) {
          setInterval(dayjs(response['Date']).hour(interval.split(':')[0]).minute(interval.split(':')[1]));
        }
        const obs = response['U_EASY_Obs'];
        if (obs) {
          setObservations(obs);
        }
        setCreated(dayjs(response['U_Created']));
        setLineID(response['LineID']);
      }).catch((error) => {
        console.error(error);
        toggleStatus('Erro ao buscar o apontamento', error, 'error');
      }).finally(() => {
        setFetchingTimesheet(false);
      });
    }
  }, [id]);

  useEffect(() => {
    if (fixedEmployee) {
      setEmployee(fixedEmployee);
    }
  }, [fixedEmployee]);

  const handleChangeEmployee = (e, value) => {
    e.preventDefault();
    setEmployee(value);
  }

  const handleChangeLocal = (e, value) => {
    e.preventDefault();
    setLocal(value);
  }

  const handleChangeProject = (e, value) => {
    e.preventDefault();
    setProject(value);
    if (value) {
      setStages(value['PM_StageCollection']?.filter((stage) => stage.StageType === 3) || []);
    } else {
      setStages([]);
    }
  }

  const handleChangeStage = (e, value) => {
    e.preventDefault();
    setStage(value);
  }

  const handleChangeDate = (value) => {
    setDate(value);
  }

  const handleChangeHoliday = (e) => {
    setHoliday(e.currentTarget.checked);
  }

  const handleChangeRetrabalho = (e, value) => {
    e.preventDefault();
    setRetrabalho(value);
  }

  const handleChangeStartTime = (value) => {
    setStartTime(value);
  }

  const handleChangeInterval = (value) => {
    setInterval(value);
  }

  const handleChangeEndTime = (value) => {
    setEndTime(value);
  }

  const handleChangeObservations = (e) => {
    setObservations(e.target.value);
  }

  const handleClose = () => {
    if (id !== null) {
      handleReset();
    }
    onClose();
  }

  const handleReset = () => {
    if (fixedEmployee === null) {
      setEmployee(null);
    }
    setLocal(null);
    setProject(null);
    setStages([]);
    setStage(null);
    setDate(dayjs());
    setHoliday(false);
    setRetrabalho(null);
    setStartTime(dayjs().set('hour', 8).set('minute', 0));
    setInterval(dayjs().set('hour', 1).set('minute', 0));
    setEndTime(dayjs().set('hour', 18).set('minute', 0));
    setObservations('');
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  }

  const handleCreate = () => {
    if (!project.FinancialProject) {
      toggleStatus('Erro ao salvar o apontamento', 'O Projeto selecionado não contem um Projeto Financeiro atrelado: '.concat(project.label), 'error');
      return;
    }
    const sessionID = Cookies.get('sessionID');
    const data = {
      DateFrom: date.format('YYYY-MM-DD'),
      DateTo: date.format('YYYY-MM-DD'),
      PM_TimeSheetLineDataCollection: [
        {
          Date: date.format('YYYY-MM-DD'),
          EndTime: endTime.format('HH:mm'),
          StartTime: startTime.format('HH:mm'),
          FinancialProject: project.FinancialProject,
          ActivityType: stage.Task,
          ProjectID: project.AbsEntry,
          StageID: stage.StageID,
          Break: interval.format('HH:mm'),
          U_EASY_Obs: observations,
          U_Local: local,
          U_Feriado: holiday ? "Sim" : null,
          U_Retrabalho: retrabalho,
          U_Created: dayjs().format('YYYY-MM-DD'),
        }
      ],
      UserID: fixedEmployee ? Cookies.get('logemployeeID') : employee.EmployeeID,
    };
    setSaving("Salvando");
    createTimeSheet(sessionID, data).then((response) => {
      handleReset();
      onSubmit();
      onClose();
    }).catch((error) => {
      console.error(error);
      toggleStatus('Erro ao salvar o apontamento', error, 'error');
    }).finally(() => {
      setSaving(false);
    });
  }

  const handleDelete = () => {
    const canEdit = dayjs().diff(created, 'days') <= 7;
    const isManager = Cookies.get('logemployeerole') === 'true';

    if (canEdit || !fixedEmployee || isManager) {
      const sessionID = Cookies.get('sessionID');
      setSaving("Excluindo");
      deleteTimeSheet(sessionID, id).then((response) => {
        handleReset();
        onSubmit();
        onClose();
      }).catch((error) => {
        console.error(error);
        toggleStatus('Erro ao excluir o apontamento', error, 'error');
      }).finally(() => {
        setSaving(false);
      });
    } else {
      toggleStatus('Falha ao excluir o apontamento', 'O apontamento não pode ser excluído após 7 dias', 'warning');
    }
  };

  const handleEdit = () => {
    const canEdit = dayjs().diff(created, 'days') <= 7;
    const isManager = Cookies.get('logemployeerole') === 'true';

    if (canEdit || !fixedEmployee || isManager) {
      const sessionID = Cookies.get('sessionID');
      const data = {
        DateFrom: date.format('YYYY-MM-DD'),
        DateTo: date.format('YYYY-MM-DD'),
        PM_TimeSheetLineDataCollection: [
          {
            LineID: lineID,
            Date: date.format('YYYY-MM-DD'),
            EndTime: endTime.format('HH:mm'),
            StartTime: startTime.format('HH:mm'),
            Break: interval.format('HH:mm'),
            U_EASY_Obs: observations,
            U_Local: local,
            U_Feriado: holiday ? "Sim" : null,
            U_Retrabalho: retrabalho,
            U_Created: dayjs().format('YYYY-MM-DD'),
          }
        ],
      };
      setSaving("Editando");
      editTimeSheet(sessionID, id, data).then((response) => {
        handleReset();
        onSubmit();
        onClose();
      }).catch((error) => {
        console.error(error);
        toggleStatus('Erro ao editar o apontamento', error, 'error');
      }).finally(() => {
        setSaving(false);
      });
    } else {
      toggleStatus('Falha ao editar o apontamento', 'O apontamento não pode ser editado após 7 dias', 'warning');
    }
  };

  const isInvalid = () => (
    startTime === null || endTime === null || endTime <= startTime || !startTime.isValid()
    || !endTime.isValid() || date === null || !date.isValid() || date > dayjs() || !employee
    || !local || !project || !stage || !observations || observations.trim() === ''
  )

  if (fixedEmployee && employee && employee.EmployeeID === undefined && id === null) {
    window.location.reload();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleCancel,
        onSubmit: handleSubmit,
        id: 'log-prod-form'
      }}
    >
      <DialogTitle>{id && 'Editar'} Apontamento de Projeto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Autocomplete
              disabled={fixedEmployee !== null || id !== null}
              fullWidth
              loadingText="Carregando..."
              onChange={handleChangeEmployee}
              options={employees}
              renderInput={(params) => <TextField {...params} label="Colaborador" required />}
              value={employee}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              onChange={handleChangeLocal}
              options={locals}
              renderInput={(params) => <TextField {...params} label="Local" required />}
              value={local}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={id !== null}
              fullWidth
              onChange={handleChangeProject}
              options={projects}
              renderInput={(params) => <TextField {...params} label="Projeto" required />}
              value={project}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={id !== null || project === null}
              fullWidth
              onChange={handleChangeStage}
              options={stages}
              renderInput={(params) => <TextField {...params} label="Tarefa" required />}
              value={stage}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  id="date"
                  label="Data"
                  onChange={handleChangeDate}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  value={date}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={<Checkbox checked={holiday} color="warning" onChange={handleChangeHoliday} />}
              label="Feriado"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              fullWidth
              onChange={handleChangeRetrabalho}
              options={retrabalhos}
              renderInput={(params) => <TextField {...params} label="Retrabalho" />}
              value={retrabalho}
            />
            <FormHelperText error>Somente em caso de Retrabalho!</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <TimePicker
                  id="start-time"
                  label="Hora Início"
                  onChange={handleChangeStartTime}
                  value={startTime}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                />
                <FormHelperText>{startTime?.format('hh:mm')}</FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <TimePicker
                  id="interval"
                  label="Intervalo"
                  onChange={handleChangeInterval}
                  value={interval}
                  slotProps={{
                    textField: {
                      required: false,
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                />
                <FormHelperText>{interval?.format('hh:mm')}</FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <TimePicker
                  id="end-time"
                  label="Hora Fim"
                  disabled={startTime === null}
                  onChange={handleChangeEndTime}
                  value={endTime}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  minTime={startTime}
                />
                <FormHelperText>{endTime?.format('hh:mm')}</FormHelperText>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              inputProps={id !== null ? { shrink: true } : {}}
              label="Descrição das atividades"
              multiline
              required
              onChange={handleChangeObservations}
              value={observations}
            />
          </Grid>
          {id !== null && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="warning">Criado em: {created?.format('DD/MM/YYYY')}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={handleDelete}
          variant="contained"
          size="large"
          sx={id == null ? { display: 'none' } : {}}
        >Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" size="large" type="reset">Cancelar</Button>
        <Button
          disabled={isInvalid()}
          color="warning"
          variant="contained"
          size="large"
          type="submit"
        >Salvar</Button>
      </DialogActions>
      <Backdrop open={saving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingTimesheet} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

TimeSheetFormModal.defaultProps = {
  open: false,
  onClose: () => { },
  employees: [],
  projects: [],
  employee: null,
  id: null,
};

export default TimeSheetFormModal;
